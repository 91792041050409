// Single Select: https://projects.invisionapp.com/share/UPZINCO7H6S#/screens/439138952
// Single Select w/ Default selection: https://projects.invisionapp.com/share/UPZINCO7H6S#/screens/439531967

fsp-ui-select {
  font-size: 14px;
  margin-bottom: 10px;

  &.no-margin-bottom {
    margin-bottom: 0;
  }

  mat-select-trigger.custom-selection-display {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
  }

  .fsp-select {
    .mat-mdc-select {
      height: 31px;
      line-height: 31px;
      background-color: var(--white);
      border: 1px solid var(--gray2);
      border-radius: 2px;
      padding: 0 10px;
      box-sizing: border-box;

      &.ng-invalid.ng-touched,
      &.error {
        border-color: var(--red);
      }

      &:focus {
        box-shadow: 0 0 0 2px var(--lightBlue2);
      }
    }
  }
  &.solid .fsp-select .mat-mdc-select {
    border: 1px solid var(--gray3);
    background-color: var(--gray3);
  }

  &.status {
    &.draft {
      .mat-mdc-select {
        border: none;
        background-color: var(--orange);
      }
    }
    &.inactive {
      .mat-mdc-select {
        border: none;
        background-color: var(--gray1);
      }
    }
    &.live {
      .mat-mdc-select {
        border: none;
        background-color: var(--green);
      }
    }

    .mat-mdc-select-arrow,
    .mat-mdc-select-value {
      color: var(--white);
    }
  }
}

.mat-mdc-select-panel.mat-primary,
.mat-mdc-autocomplete-panel.mdc-menu-surface {
  border-radius: 2px;
  background-color: var(--white);
  margin-top: 7px !important;
  padding: 0 !important;

  .mat-mdc-option {
    height: 31px;
    line-height: 31px;
    min-height: auto;

    .mdc-list-item__primary-text {
      overflow: hidden;
      text-overflow: ellipsis;
      height: 31px;
      line-height: 31px;
      white-space: nowrap;
    }

    &.custom-selection-display {
      position: relative;
      padding-right: 40px;
      .mdc-list-item__primary-text .color-preview {
        position: absolute;
        top: 5px;
        right: 16px;
      }
    }

    &.mdc-list-item--selected mat-pseudo-checkbox {
      display: none;
    }

    &.mdc-list-item--selected:not(.mdc-list-item--disabled) {
      .mdc-list-item__primary-text {
        color: var(--white);
      }
      background-color: var(--blue);
    }

    &:hover:not(.mdc-list-item--disabled),
    &:focus:not(.mdc-list-item--disabled) {
      .mdc-list-item__primary-text {
        color: var(--black);
      }
      background-color: var(--gray3);
    }
  }
}

.mat-mdc-select-arrow,
.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: var(--black);
}

.mat-mdc-form-field
  .mat-mdc-select.mat-mdc-select-invalid
  .mat-mdc-select-arrow,
.mat-mdc-form-field .mat-mdc-select.error .mat-mdc-select-arrow {
  color: var(--red);
}

// underline when active
.mat-mdc-form-field.mat-focused .mat-mdc-form-field-ripple {
  background-color: var(--blue);
}

.mat-mdc-optgroup.small .mat-mdc-optgroup-label {
  min-height: 31px;
}

.mat-mdc-optgroup-label {
  color: black;
  font-weight: bold;
}

.remove-min-width {
  .mat-mdc-form-field-infix {
    width: 100% !important;
  }
}

.requirement-wrapper {
  .remove-min-width {
    .mat-mdc-form-field-infix {
      width: 180px !important;
    }
  }
}

.suffix {
  padding-right: 20px;
}
