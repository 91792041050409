.fsp-filters {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: end;
  gap: 10px;
  background-color: var(--gray5);
  padding: 15px;

  &.modal-view {
    grid-auto-flow: row;
    grid-template-columns: 1fr;

    // Add padding around link on mobile to make it easier to click
    .clear-all-link {
      padding: 10px;
      margin-left: -10px;
    }
  }

  .fsp-input.mat-mdc-input-element {
    margin-bottom: 0;
  }

  .open-filter-modal-link {
    display: none;
  }

  .clear-all-link,
  .open-filter-modal-link {
    line-height: 31px;
    justify-self: start;
    text-decoration: none;
  }
}

.fsp-filters-split {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  padding: 15px;
  background-color: var(--gray5);

  &.modal-view {
    grid-auto-flow: row;
    grid-template-columns: 1fr;

    .filters {
      grid-auto-flow: row;
      grid-template-columns: 1fr;
    }
  }

  &.reverse-order {
    .left {
      order: 2;
    }
  }

  .filters {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    align-items: end;
    gap: 10px;
  }

  .left {
    justify-content: start;
  }

  .right {
    justify-content: end;
  }

  .fsp-input.mat-mdc-input-element {
    margin-bottom: 0;
  }

  .open-filter-modal-link {
    display: none;
  }

  .clear-all-link,
  .open-filter-modal-link {
    line-height: 31px;
    justify-self: start;
    text-decoration: none;
  }
}

.fsp-tabbed-filters-container {
  .fsp-tab-nav.mat-mdc-tab-nav-bar {
    padding-top: 5px;
    margin: 0;
  }
}
