.fsp-reminder-link {
  color: var(--gray1);
  font-size: 14px;
  line-height: 16px;
  min-height: 39px;

  fa-icon {
    margin-right: 5px;
  }

  .fsp-reminder-link-body {
    display: flex;
    margin-top: 6px;

    .clear-icon {
      margin-left: 5px;
      cursor: pointer;
    }
  }
}
