.aircraft-list {
  mat-list-item {
    span.mdc-list-item__content {
      padding: 0 !important;
    }
  }
}

.aircraft-groups-location-list {
  .aircraft-group-list {
    mat-list-item {
      &:hover {
        background-color: transparent !important;
      }
      span.mdc-list-item__content {
        display: block !important;
        padding: 0 !important;
      }
    }
  }
}

.cdk-drag-preview {
  padding: 0;

  .group-list-item-wrapper {
    height: auto;
    background-color: var(--gray4);
    padding: 18px 15px;
    mat-list-item {
      &:hover {
        background-color: transparent !important;
      }
      span.mdc-list-item__content {
        display: block !important;
        padding: 0 !important;
      }
    }

    fa-icon {
      margin: 0 !important;
    }
    .display-name {
      font-weight: bold;
    }
    .list-header {
      padding: 0;
    }
  }
  .aircraft-list {
    background-color: var(--white);
    padding-left: 20px;
    max-width: 550px;
    .aircraft-list-item {
      border: none !important;
      margin-bottom: 15px;
    }
  }

  .aircraft-list-item-wrapper {
    display: block;
    width: 100%;
    .left-container {
      width: 100%;
      .display-info-container {
        align-items: center;
        width: 100%;
        .aircraft-info-container {
          display: flex;
          flex-direction: column;
          .tailnumber {
            font-weight: bold;
            font-size: 14px;
          }
          .make-and-model {
            font-size: 14px;
          }
        }

        .aircraft-image {
          margin-right: 40px;
          width: 100px;
          max-height: 75px;
        }

        .draggable-icon {
          margin-left: auto;
        }
        mat-list-item {
          span.mdc-list-item__content {
            padding: 0 !important;
          }
          border: none;
        }
      }
    }
  }
}
