.mat-mdc-autocomplete-panel {
  & .mat-mdc-option {
    min-height: auto;
    height: 31px;
    line-height: 31px;
    font-size: inherit;
    white-space: nowrap;
  }
}

.mdc-menu-surface.mat-mdc-autocomplete-panel {
  background-color: var(--white);
}
