@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

.prebootstrapspinner {
  animation: pulse 2s infinite;
  height: 100%;
  margin: auto auto 0;
  max-width: 130px;

  img {
    height: 100%;
    width: 100%;
  }
}

.prebootstrap-body {
  overflow: hidden;
}
