fsp-ui-autocomplete-multi-select {
  .fsp-form-field.mat-form-field-appearance-outline {
    .mat-mdc-form-field-icon-prefix,
    .mat-mdc-form-field-icon-suffix {
      // make arrow a little higher than other input icons
      top: 6px;
    }
  }

  .mat-mdc-input-element {
    margin: 0;
    cursor: pointer;
  }

  .mat-mdc-form-field-infix {
    height: 31px;
  }
}

.fsp-autocomplete-multi-select-container {
  .fsp-input {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.fsp-autocomplete-multi-select {
  &.fsp-dropdown-menu.mat-mdc-menu-panel {
    max-width: fit-content;
    min-width: 180px;
    overflow: visible;

    .mat-mdc-menu-content:not(:empty) {
      padding: 0;
    }
  }

  .mat-mdc-form-field-suffix .arrow-icon {
    position: relative;
    top: -2px;
  }

  .clear-input-icon {
    right: 8px;
    position: relative;
    cursor: pointer;
  }

  .multi-select-group {
    fsp-ui-label {
      display: block;
      margin-top: 8px;
    }
  }

  .multi-select-options {
    padding: 5px 10px;
    max-height: 280px;

    &.scrollbar {
      overflow-y: scroll;
    }

    .fsp-checkbox {
      display: block;
    }
  }

  .header-action-container {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    align-items: center;
    border-bottom: 1px solid var(--gray3);
    padding: 10px;
    line-height: normal;
  }

  .apply-filter-button-container {
    border-top: 1px solid var(--gray3);
    padding: 10px;

    &:not(.show-filter-type-toggle) {
      .fsp-button {
        width: 100%;
      }
    }
  }
}

.fsp-autocomplete-tabs-container {
  padding: 10px 10px 0 10px;

  mat-button-toggle-group {
    display: grid;
    grid-auto-flow: column;
  }
}
