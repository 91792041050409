// Input: https://projects.invisionapp.com/d/main/#/console/20840765/438559163/preview
// TODO: Remove padding-left on input when there is overflow
.bottom-counter {
  display: flex;
  float: right;
}

.fsp-input {
  border: 1px solid var(--gray2) !important;
}

.fsp-input.mat-mdc-input-element {
  background-color: var(--white) !important;
  width: 100%;
  height: 31px !important;
  border-radius: 2px;
  caret-color: var(--gray1);
  padding: 0 8px;
  margin: 0;
  box-sizing: inherit;

  &:disabled {
    color: var(--gray2);
  }

  &.gray-disabled {
    background: var(--gray4) !important;
  }

  &.mdc-text-field__input {
    border: 1px solid var(--gray2) !important;
  }

  &.currency {
    padding-left: 26px;
  }

  &:focus {
    box-shadow: 0 0 0 2px var(--lightBlue2);
  }

  &.no-margin {
    margin: 0;
  }

  &.ng-dirty.ng-invalid,
  &.ng-touched.ng-invalid,
  &.error {
    border-color: var(--red) !important;
  }

  &.fsp-input-with-prefix-and-suffix {
    padding: 0 32px;
  }

  &.fsp-input-with-prefix {
    padding-left: 32px;
  }

  &.fsp-input-with-suffix {
    padding-right: 32px;

    &.two-suffix-icons {
      padding-right: 50px;
    }
  }
}

textarea.fsp-input.mat-mdc-input-element {
  padding: 4px 8px;
}

.fsp-input-form-field {
  width: 100%;

  .inline-counter {
    margin-left: 5px;
  }
}

.fsp-search-helper-text {
  position: absolute;
  background-color: var(--white);
  color: var(--gray1);
  padding: 10px;
  border-radius: 2px;
  box-shadow: var(--dropDownBoxShadow);
  z-index: 1; // Table headers appeared on top of helper text window
}

.fsp-form-field .currency-symbol {
  color: var(--gray1);
}

fsp-autocomplete-chips-input .mat-chip-input {
  border: none;
}
