.status-indicator {
  display: grid;
  gap: 10px;
  grid-auto-flow: column;
  justify-content: start;
  line-height: 22px;

  .color-indicator {
    border-radius: 50%;
    height: 10px;
    margin-bottom: 2px;
    width: 10px;
  }
}
