// Dropdown: https://projects.invisionapp.com/d/main/#/console/20840765/439365430/preview

.mat-mdc-menu-item:hover:not([disabled]),
.mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-mdc-menu-item-highlighted:not([disabled]) {
  color: var(--black);
  background-color: var(--gray3) !important;
}

.fsp-dropdown-menu.mat-mdc-menu-panel {
  background-color: var(--white);
  min-height: auto;

  .mat-mdc-menu-content:not(:empty) {
    padding: 3px 0;

    .mat-mdc-menu-item {
      min-height: 31px;

      &:active {
        color: var(--white);
        background-color: var(--blue);
      }
    }
  }
}
