.fsp-detail-card {
  background-color: var(--gray5);
  margin-bottom: 20px;

  &.white-card {
    background-color: var(--white);
  }

  .info-icon {
    margin-left: 10px;
    cursor: pointer;
    color: var(--blue);
    font-size: 16px;
    position: relative;
  }

  fsp-ui-table {
    table {
      margin-top: 0 !important;
    }
  }
}

.fsp-detail-card:last-of-type {
  margin-bottom: 0;
}

.fsp-detail-card-header {
  a {
    text-decoration: none !important;

    &:hover {
      text-decoration: underline !important;
    }
  }

  fsp-ui-select {
    margin-left: 10px;
    margin-bottom: 0;
    margin-right: 40px;
  }

  &.is-editing {
    border-top-left-radius: 3px;
    border-left: 4px solid var(--blue);
  }

  &.dynamic-height {
    height: auto;
    min-height: 45px;
  }

  .slide-toggle-container {
    display: flex;
    gap: 10px;
    line-height: 31px;
    height: 29px;
  }

  &.danger {
    border-left: 4px solid var(--red);
    border-top-left-radius: 4px;
  }

  &.primary {
    border-left: 4px solid var(--blue);
    border-top-left-radius: 4px;
  }

  &.warning {
    border-left: 4px solid var(--orange);
    border-top-left-radius: 4px;
  }
}

.fsp-detail-card-info {
  width: 100%;
  padding: 20px;
  background-color: var(--gray5);

  &.card-details-grid {
    display: grid;
    gap: 0 20px;
    grid-template-columns: auto auto;
    justify-content: start;

    .card-detail-value {
      font-weight: bold;
    }
  }

  h3 {
    margin-bottom: 10px !important;
  }

  p {
    font-size: 14px;
    line-height: 16px;
  }
}

.fsp-detail-card-label {
  margin-bottom: 3px;
}
