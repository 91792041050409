// This file will be deleted once all AngularJS components are gone

// page header
#main {
  h1 {
    color: var(--black);
    font-size: 24px;
    font-weight: 400;
    margin: 0 0 30px;
    line-height: 28px;

    &.widget-item-data {
      margin-bottom: 0;
    }
  }

  .fsp-page-header h1 {
    margin: 0;
  }
}
