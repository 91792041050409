mat-radio-group,
.fsp-radio-button-container {
  display: grid;
  gap: 5px;

  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control {
    width: 17px;
    height: 17px;
  }

  .mat-mdc-radio-button .mdc-radio {
    width: 17px;
    height: 17px;
    padding: 0;
  }

  .fsp-radio-button {
    .mat-mdc-radio-touch-target,
    .mdc-radio__background,
    .mdc-radio__outer-circle,
    .mdc-radio__inner-circle {
      width: 17px;
      height: 17px;
    }

    .mdc-form-field > label {
      margin-bottom: 0;
      padding-left: 8px;
      padding-top: 2px;
    }

    .mat-mdc-radio-label-content {
      padding-top: 2px;
    }

    .mdc-radio__outer-circle {
      border-width: 1px;
    }

    .mdc-radio__inner-circle {
      border-color: transparent !important;
      border-width: 8.5px;
    }

    &.mat-mdc-radio-checked {
      .mdc-radio__outer-circle {
        border: 5px solid var(--blue) !important;
      }
    }

    .mat-mdc-radio-ripple {
      display: none;
    }

    &.solid {
      .mdc-radio__outer-circle {
        background: var(--white);
      }
    }

    &.inverted {
      .mdc-form-field > label {
        color: var(--white) !important;
      }

      .mdc-radio__inner-circle {
        border-color: var(--blue) !important;
      }

      &.mat-mdc-radio-checked {
        .mdc-radio__outer-circle {
          border: 5px solid var(--white) !important;
        }
      }
    }
  }

  &.error {
    .fsp-radio-button .mdc-radio__outer-circle {
      border: 1px solid var(--red) !important;
    }
  }
}
