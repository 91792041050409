fsp-ui-timer-button button.fsp-flat-button.fsp-header-button {
  border-radius: 4px;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  padding: 0 5px !important;
  position: relative;
  top: -7px;

  & > span {
    height: 25px;
  }
  .arrow-icon {
    margin-left: 8px;
  }

  &.green,
  &.green:disabled {
    background-color: var(--white);
    border: 1px solid var(--green);
    color: var(--green);

    &:hover {
      background-color: var(--lightGreen);
    }
  }

  &.green:disabled {
    background-color: var(--gray3);
    opacity: 0.5;
  }
}

fsp-ui-timer-button .fsp-split-button-group .fsp-split-button {
  mat-spinner.mat-mdc-progress-spinner circle,
  mat-spinner.mat-mdc-spinner circle {
    stroke: var(--white);
  }
}

fsp-ui-timer-button .fsp-split-button-group .fsp-split-button.green {
  mat-spinner.mat-mdc-progress-spinner circle,
  mat-spinner.mat-mdc-spinner circle {
    stroke: var(--green);
  }
}

.running-timer {
  .fsp-header-button {
    top: -6px;
  }
}
