.fsp-blue-form-card {
  .fsp-blue-card {
    padding: 10px 15px;
    background-color: var(--lightBlue);
  }

  .fsp-input.mat-mdc-input-element {
    margin-bottom: 0;
  }
}

@media (max-width: 959px) {
  .fsp-blue-form-card {
    .fsp-select.label-left {
      display: block !important;
    }
  }
}

@media (max-width: 599px) {
  .fsp-blue-form-card {
    .buttons {
      display: grid;
      grid-auto-flow: row;
      grid-template-columns: 1fr;

      .save-cancel-container {
        grid-auto-flow: row;
      }
    }
  }
}
