fsp-ui-table {
  &.no-top-margin table {
    margin-top: 0;
  }
  &.no-bottom-margin table {
    margin-bottom: 0;
  }
  table {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;

    &.tight {
      td {
        padding: 10px 20px;
      }
    }

    &.content-top-aligned {
      td {
        vertical-align: top;
      }
    }

    &.striped {
      tr:nth-child(even) td {
        background: var(--gray5);
      }
    }
  }

  th {
    color: var(--black);
    background-color: var(--lightBlue);
    padding: 10px 20px;

    &.clickable {
      cursor: pointer;
    }

    .mat-sort-header-content {
      text-align: left;
    }
  }

  tr {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: var(--gray3);

    &.no-border-bottom {
      border-bottom: none;
    }
  }

  td {
    padding: 15px 20px;
  }

  td,
  th {
    color: var(--black);
    line-height: normal;

    a {
      &.isSelected {
        text-decoration: underline !important;
      }
      line-height: 20px;
    }
  }

  .mat-divider {
    margin-top: 20px;
  }

  th.checkbox-column {
    padding: 10px 0 10px 10px;
    width: 30px;
  }

  // Styling for highlighted keywords. See replaceWithHighlightedText for more detais about selector choice.
  b {
    background-color: var(--yellow);
    font-weight: bold;
  }

  .warning,
  .expired,
  .misconfigured {
    font-weight: bold;
  }

  .warning {
    color: var(--orange);
  }

  .misconfigured,
  .expired,
  .error {
    color: var(--red);
  }

  .fsp-chip {
    margin-right: 5px;
  }

  .empty-text {
    display: block;
    color: var(--gray1);
    padding: 0 20px 15px 20px;

    &.top-margin {
      margin-top: 15px;
    }
  }

  th,
  td {
    &.align-right {
      text-align: right;
    }
  }

  fsp-ui-small-table {
    &.layout-one,
    &.layout-two {
      fsp-ui-small-table-row {
        display: grid;
        align-items: start;
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }

    &.layout-one {
      .secondary-text {
        line-height: inherit;
        margin-bottom: 0;
      }

      fsp-ui-small-table-row {
        grid-template-columns: 120px 1fr;
        gap: 20px;
      }
    }

    &.layout-two {
      fsp-ui-small-table-row {
        grid-template-columns: 1fr;
        gap: 0;
      }
    }

    .fsp-small-table-container {
      border-bottom: 1px solid var(--gray3);
      padding: 15px 10px;

      &:first-of-type {
        border-top: 1px solid var(--gray3);
        margin-top: 15px;
      }

      &.white {
        background-color: var(--white);
      }

      .secondary-text {
        text-transform: uppercase;
      }

      .autobilling-text,
      .payment-type-text {
        text-transform: none;
      }

      div {
        color: var(--black);
        &.red {
          color: var(--red);
        }
      }

      .block {
        display: block;
      }
    }

    .fsp-select-all-header {
      background-color: var(--lightBlue);
      border-bottom: 1px solid var(--gray3);
      padding: 8px 20px;
    }
  }

  th.expandable-column {
    min-width: 450px;
  }

  .link-with-icon {
    white-space: nowrap;

    fa-icon {
      margin-right: 5px;
    }
  }

  .expandable-cell-container {
    display: grid;
    grid-auto-flow: column;
    align-items: start;
    gap: 5px;

    .description-link-container {
      display: grid;
      grid-auto-flow: column;
      justify-content: start;
      gap: 5px;
    }

    .meta-data {
      align-self: start;
      justify-self: end;
      line-height: 20px;
      margin-left: 5px;
    }

    .bullet {
      color: var(--gray1);
      line-height: 20px;
    }
  }

  .expandable-cell {
    display: inline-block;
    max-width: 100%;
    max-height: 20px;
    line-height: 20px;
    overflow: hidden;

    &.expanded {
      max-height: fit-content;
      white-space: inherit;
      overflow: inherit;
      text-overflow: initial;
    }
  }
}

.empty-text-with-big-icon {
  display: grid;
  gap: 15px;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  color: var(--gray1);
  margin-top: 15px;

  .big-icon {
    font-size: 50px;
    color: var(--gray3);
  }

  &.left-padding {
    padding-left: 20px;
  }

  &.padding {
    padding: 20px;
  }
}

td.cdk-column-actions {
  float: right;
}

.cdk-column-maintenanceResourceName {
  min-width: 200px;
}

.cdk-column-lastModifiedByUserName {
  min-width: 125px;
}

td.cdk-column-estimatedTimeToCompletion {
  white-space: nowrap;
}

.clickable-rows tbody tr:hover {
  background-color: var(--gray4);
}

.dashboard-my-reservations-table fsp-ui-table table {
  margin: 0;
}

@media (max-width: 1049px) {
  fsp-app-dashboard-my-reservations {
    fsp-ui-table fsp-ui-small-table .fsp-small-table-container:first-of-type {
      border-top: none;
      margin-top: 0;
    }
  }
}

fsp-ui-table fsp-ui-small-table .fsp-small-table-container div.status-box,
.status-box {
  border-radius: 3px;
  color: var(--white);
  font-weight: 500;
  height: 36px;
  line-height: 36px;
  text-align: center;
  text-transform: capitalize;
  width: 36px;

  &.green {
    background-color: var(--green);
  }

  &.gray {
    background-color: var(--gray1);
  }

  &.light-green {
    background-color: var(--lightGreen);
    color: var(--green);
    border: 1px solid var(--green);

    &:hover {
      background-color: var(--lightGreen2);
    }
  }

  &.light-gray {
    background-color: var(--gray3);
    color: var(--gray1);
    border: 1px solid var(--gray1);

    &:hover {
      background-color: var(--gray2);
    }
  }

  &.light-orange {
    background-color: var(--lightOrange);
    color: var(--orange);
    border: 1px solid var(--orange);

    &:hover {
      background-color: var(--lightOrange2);
    }
  }

  &.orange {
    background-color: var(--orange);
  }
}

fsp-app-graduation-requirements {
  fsp-ui-table fsp-ui-small-table .fsp-small-table-container:first-of-type {
    border-top: none;
    margin-top: 0;
  }
}

// mobile styling
@media (max-width: 599px) {
  fsp-ui-table {
    .expandable-cell-container {
      grid-auto-flow: row;
      gap: 0;

      .meta-data {
        justify-self: start;
        line-height: normal;
        margin-top: 10px;
        margin-left: 0;
      }
    }
  }
}
