// TODO: Once new styleguide is applied to entire app, .fsp-styleguide needs to be removed from everything
@use './active-border';
@use './alerts';
@use './attachment';
@use './autocomplete';
@use './autocomplete-multi-select';
@use './blue-form-card';
@use './breadcrumbs';
@use './buttons';
@use './card';
@use './card-footer-bar';
@use './checkbox';
@use './chip';
@use './colors';
@use './date-picker';
@use './datetime-picker';
@use './detail-card';
@use './dropdown-menu';
@use './expansion-panel';
@use './filters';
@use './form-field';
@use './header';
@use './icons';
@use './infinite-scroll-viewport';
@use './input';
@use './label';
@use './layout';
@use './legacy-overrides';
@use './list';
@use './loading';
@use './main-nav';
@use './modal';
@use './navigation';
@use './paginator';
@use './popover';
@use './print';
@use './progress-bar';
@use './radio-button';
@use './reminder-link';
@use './rich-text-editor';
@use './select';
@use './snack-bar';
@use './tab-bar';
@use './table';
@use './toggle';
@use './toggle-group';
@use './tooltip';
@use './typography';
@use './upload';
@use './widget';

/*
    Grid sizing:

    $mat-xs: max-width: 599px
    $mat-sm: min-width: 600px and max-width: 959px
    $mat-md: min-width: 960px and max-width: 1279px
    $mat-lg: min-width: 1280px and max-width: 1919px
    $mat-xl: min-width: 1920px and max-width: 5000px
    $mat-lt-sm: max-width: 599px
    $mat-lt-md: max-width: 959px
    $mat-lt-lg: max-width: 1279px
    $mat-lt-xl: max-width: 1919px
    $mat-gt-xs: min-width: 600px
    $mat-gt-sm: min-width: 960px
    $mat-gt-md: min-width: 1280px
    $mat-gt-xl: min-width: 1920px
**/
