.fsp-checkbox {
  &.mat-mdc-checkbox {
    display: inline-block;
  }

  &.mat-mdc-checkbox .mdc-checkbox {
    padding: 5px;
    margin: 0;

    .mdc-checkbox__native-control,
    .mat-mdc-checkbox-touch-target {
      width: 24px;
      height: 24px;
    }
  }

  &.mat-mdc-checkbox-disabled .mdc-checkbox {
    opacity: 0.5;
  }

  &.mat-mdc-checkbox-disabled.mat-mdc-checkbox-checked {
    .mdc-checkbox__background {
      background-color: var(--blue) !important;
      border-color: var(--blue) !important;
    }
  }

  .mdc-checkbox__background {
    width: 16px !important;
    height: 16px !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    .mdc-checkbox__checkmark-path {
      stroke-width: 4px;
    }
  }

  .mdc-form-field {
    margin-left: -5px;
  }

  label {
    margin-bottom: 0;
  }

  &.bold label {
    font-weight: bold;
  }

  &.inactive label {
    color: var(--gray2);
  }

  &.white-space-wrap {
    .mat-checkbox-layout {
      white-space: inherit;
    }
  }

  &.inverted {
    .mdc-checkbox__background {
      background-color: var(--white) !important;
      .mdc-checkbox__checkmark-path {
        stroke: var(--blue) !important;
      }
    }
  }

  &.inverted.mat-mdc-checkbox-checked {
    .mdc-checkbox__background {
      border-color: var(--white) !important;
    }
  }
}
