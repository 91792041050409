@media print {
  .material-dialog-open mat-drawer-container,
  .modal-open mat-drawer-container,
  .fsp-form-field.mat-form-field-appearance-outline
    .mat-mdc-form-field-text-suffix,
  .fsp-form-field.mat-form-field-appearance-outline
    .mat-mdc-form-field-icon-suffix,
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing,
  .buttons {
    display: none !important;
  }

  .cdk-overlay-container,
  .cdk-global-overlay-wrapper,
  .cdk-overlay-pane {
    position: relative !important;
  }

  .fsp-input.mat-mdc-input-element,
  fsp-ui-textarea .fsp-ui-textarea,
  fsp-ui-select .fsp-select .mat-mdc-select {
    background-color: var(--white) !important;
    border-color: var(--gray2) !important;
    caret-color: var(--gray1) !important;
    -webkit-print-color-adjust: exact;
  }

  form {
    display: block !important;
  }

  section.fsp-detail-card {
    background-color: var(--gray5) !important;
    -webkit-print-color-adjust: exact;
  }

  mat-dialog-container.mat-mdc-dialog-container,
  mat-dialog-content.mat-mdc-dialog-content,
  mat-dialog-actions.mat-mdc-dialog-actions {
    display: block !important;
    overflow: visible !important;
    max-height: none !important;
    min-height: auto !important;
    height: auto !important;
  }

  mat-dialog-container.mat-mdc-dialog-container {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    z-index: 9999 !important;
    background-color: var(--white) !important;
    color: var(--black) !important;
    -webkit-print-color-adjust: exact;
    width: 100% !important;
    margin-top: 0 !important;
  }

  mat-dialog-actions.mat-mdc-dialog-actions {
    margin: 0 !important;
  }

  .mat-dialog-action-contents {
    padding: 5px 20px !important;
  }
}
