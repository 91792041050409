// Colors: https://projects.invisionapp.com/share/UPZINCO7H6S#/screens/438787954
// There is also a colors.constants file in the shared module to support passing colors to third party packages such as google charts
:root {
  --chartPurple: #844ba8;
  --chartLightPurple: #e0d2e9;
  --black: #000000;
  --blue: #1374c9;
  --blue2: #0020e4;
  --borderGray: #d8d8d8;
  --borderDarkGray: #999999;
  --darkGreen: #0f4122;
  --darkBlue: #0c4c85;
  --darkRed: #6b140c;
  --gray1: #595959;
  --gray2: #919191;
  --gray3: #e5e5e5;
  --gray4: #f5f5f5;
  --gray5: #fafafa;
  --gray6: #606060;
  --gray7: #ddd;
  --gray8: #333333;
  --green: #1f8446;
  --green2: #4a9d86;
  --lightBlue: #e7f1fa;
  --lightBlue2: #cce4f7;
  --lightBlue3: #e9ecff;
  --lightBlue4: #c0cafd;
  --lightBlue5: #c4dcf2;
  --lightGreen: #e8f2ec;
  --lightGreen2: #c0e4cf;
  --lightGreen3: #e7ece8;
  --lightOrange: #fbf1e7;
  --lightOrange2: #f3ceae;
  --lightPurple: #f8edfe;
  --lightPurple2: #eac6fe;
  --lightRed: #fbe9e8;
  --lightRed2: #f1c6c2;
  --lightRed3: #fdbeba;
  --orange: #dc7418;
  --purple: #a70ef5;
  --purple2: #844ba8;
  --red: #d72919;
  --white: #ffffff;
  --yellow: #fffba3;
}
