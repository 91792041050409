// Buttons: https://projects.invisionapp.com/d/main/#/console/20840765/438566876/inspect
$button-height: 31px;

// clickable text
.clickable-text {
  color: var(--blue);
  text-decoration: underline;
  cursor: pointer;
}

// icon buttons
fsp-ui-icon-button {
  height: $button-height;
  .fsp-button.fsp-icon-button {
    padding: 0;
    width: $button-height !important;

    &.blue-icon {
      color: var(--blue);
    }

    &.red-icon {
      color: var(--red);
    }

    &.transparent-background {
      background: transparent;
    }
  }
}

// icon buttons
fsp-ui-icon-button > button.fsp-button.fsp-icon-button {
  padding: 0 10px;
  min-width: auto;
}

// dropdown buttons
button.fsp-button.fsp-flat-button.dropdown {
  padding: 0 10px;

  & > span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  &:not(.primary) > span fa-icon {
    margin-bottom: 1px;
  }
}

.fsp-icon-button:hover {
  cursor: pointer;
}

// all buttons
button.fsp-button.fsp-flat-button,
.fsp-split-button-group .fsp-split-button,
.mat-mdc-fab.fsp-fab-button {
  .mat-mdc-progress-spinner circle,
  .mat-mdc-spinner circle {
    stroke: var(--black);
  }

  &.actions-menu-trigger {
    padding: 0 10px;
  }

  &.primary {
    &:hover {
      background-color: var(--darkBlue);
    }
    &:disabled,
    &:has(button:disabled) {
      opacity: 0.25;
    }
    .mat-mdc-progress-spinner circle,
    .mat-mdc-spinner circle {
      stroke: var(--white);
    }
  }

  &.red {
    background-color: var(--red);
    color: var(--white);

    &:hover,
    &:active {
      background-color: var(--darkRed);
    }

    &:active {
      border: 2px solid var(--red);
    }

    &:disabled,
    &:has(button:disabled) {
      opacity: 0.25;
      color: var(--white);
      background-color: var(--red);
    }

    .mat-mdc-progress-spinner circle,
    .mat-mdc-spinner circle {
      stroke: var(--white);
    }
  }

  &.green {
    background-color: var(--green);
    color: var(--white);

    &:hover,
    &:active {
      background-color: var(--darkGreen);
    }

    &:active {
      border: 2px solid var(--green);
    }

    &:disabled,
    &:has(button:disabled) {
      opacity: 0.25;
      color: var(--white);
      background-color: var(--green);
    }

    .mat-mdc-progress-spinner circle,
    .mat-mdc-spinner circle {
      stroke: var(--white);
    }
  }

  &.white {
    background-color: var(--white);
    border: 1px solid var(--blue);
    color: var(--blue);

    &:hover,
    &:active {
      background-color: var(--lightBlue);
    }

    &:active {
      border: 2px solid var(--blue);
    }

    &:disabled,
    &:has(button:disabled) {
      opacity: 0.25;
      color: var(--blue);
      background-color: var(--white);
    }

    .mat-mdc-progress-spinner circle,
    .mat-mdc-spinner circle {
      stroke: var(--blue);
    }
  }

  &.large {
    height: 45px;
    font-size: 16px;
    line-height: 19px;
  }
  &:hover {
    background-color: var(--gray2);
  }
}

button.fsp-button {
  font-family: var(--mainFont);
  font-size: 14px;
  & > span {
    align-items: center;
    display: flex;
    gap: 10px;
    height: 100% !important;
    white-space: nowrap;
  }
  &.aircraft-actions-toggle {
    padding: 0;
    & > span {
      justify-content: end;
      padding: 0 16px;
      width: 100%;
    }
  }
  &:disabled,
  &:has(button:disabled) {
    opacity: 0.5;
  }
}

button.fsp-button.fsp-flat-button {
  background-color: var(--gray3);
  border: 2px solid transparent;
  border-radius: 2px;
  font-weight: 400;
  height: $button-height;
  line-height: normal;
  padding: 0 20px;
}

button.fsp-button.fsp-flat-button.primary {
  background-color: var(--blue);
  color: var(--white);
}

button.fsp-button.fsp-stroked-button.primary {
  border: 1px solid var(--blue);
  font-weight: 400;
}

fsp-ui-split-button > .fsp-split-button-group {
  border-radius: 2px;
  border-width: 0;
}

fsp-ui-split-button > .fsp-split-button-group > .fsp-split-button,
fsp-ui-timer-button .fsp-split-button-group > .fsp-split-button {
  background-color: var(--gray3);
  color: var(--black);
  height: $button-height;
  &.primary {
    background-color: var(--blue);
    color: var(--white);
    &:not(:first-child) {
      border-left-color: var(--white);
    }
  }
  &:not(:first-child) {
    border-left-color: var(--black);
  }
  & > button {
    & > span {
      align-items: center;
      display: flex;
      height: $button-height;
      justify-content: center;
      padding: 0 10px;
    }
  }
  &.toggle fa-icon,
  &.menu fa-icon {
    margin-top: 1px;
  }
}

.mat-mdc-fab.fsp-fab-button {
  position: fixed;
  right: 20px;
  bottom: 10px;
}

@media (max-width: 667px) {
  .schedule-reservation-buttons {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    gap: 10px;
    fsp-ui-split-button {
      flex: auto;
      .fsp-split-button-group {
        width: 100%;
        .fsp-split-button:not(.menu) {
          width: calc(100% - 28px);
        }
      }
    }
    fsp-ui-button {
      flex: auto;
      button {
        width: 100%;
      }
    }
  }
  .reservation-split-button {
    .fsp-split-button-group {
      width: 100%;
      .fsp-split-button:not(.menu) {
        width: calc(100% - 28px);
      }
    }
  }
}

@media (max-width: 599px) {
  .mat-mdc-fab.fsp-fab-button {
    height: 40px;
    width: 40px;

    .mat-button-wrapper {
      padding: 0;
    }
  }
}
