fsp-ui-paginator {
  width: 100%;
  min-width: 300px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: nowrap;

  button {
    background-color: transparent;
    border-color: transparent;
    margin: 0;
    padding: 0;
    color: var(--blue);

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      text-decoration: underline;
      color: var(--darkBlue);
    }

    &:disabled {
      color: var(--gray1);
      text-decoration: none;
    }
  }

  .faChevronLeft {
    margin-right: 7px;
  }

  .first {
    margin-right: 10px;
  }

  .previous {
    margin-right: 20px;
  }

  .next {
    margin-left: 20px;
  }

  .last {
    margin-left: 10px;
  }

  .faChevronRight {
    margin-left: 7px;
  }

  .page-numbers {
    display: flex;
    align-items: center;
    color: var(--black);
  }
}
