.mat-datepicker-actions {
  display: grid !important;
  grid-template-columns: 40px 8px 40px 8px 45px 1fr;
  grid-template-areas: 'hours-container colon-container minutes-container . meridian-container done-button';
  margin: 0 13px;
  justify-content: start !important;
}

.hours-container {
  grid-area: hours-container;
  grid-template-areas:
    'hours-angle-up'
    'hours'
    'hours-angle-down';
  justify-content: center;
  width: 35px;

  .hours {
    grid-area: hours;
    padding-left: 5px;
    width: 100%;
  }

  .hours-angle-up {
    display: grid;
    grid-area: hours-angle-up;
    justify-content: center;
  }

  .hours-angle-down {
    display: grid;
    grid-area: hours-angle-down;
    justify-content: center;
  }
}

.colon-container {
  grid-area: colon-container;
  padding-bottom: 10px;
}

.minutes-container {
  grid-area: minutes-container;
  grid-template-areas:
    'minutes-angle-up'
    'minutes'
    'minutes-angle-down';
  justify-content: center;
  width: 35px;

  .minutes {
    grid-area: minutes;
    padding-left: 5px;
    width: 100%;
  }

  .minutes-angle-up {
    display: grid;
    grid-area: minutes-angle-up;
    justify-content: center;
  }

  .minutes-angle-down {
    display: grid;
    grid-area: minutes-angle-down;
    justify-content: center;
  }
}

.meridian-container {
  grid-area: meridian-container;
  grid-template-areas:
    'meridian-angle-up'
    'meridian'
    'meridian-angle-down';
  justify-content: center;
  width: 35px;

  .meridian {
    grid-area: meridian;
    padding: 0 5px !important;
    width: 100%;
  }

  .meridian-angle-up {
    display: grid;
    grid-area: meridian-angle-up;
    justify-content: center;
  }

  .meridian-angle-down {
    display: grid;
    grid-area: meridian-angle-down;
    justify-content: center;
  }
}

.done-button {
  grid-area: done-button;
  justify-self: end;
  width: 75px;
}

.hidden-input {
  visibility: hidden;
  height: 0 !important;
  position: absolute;
}

.angle-up {
  cursor: pointer;
}

.angle-up-icon {
  cursor: pointer;
  color: var(--gray1);
  font-size: 27px;
}

.angle-down {
  cursor: pointer;
  line-height: 36px;
}

.angle-down-icon {
  cursor: pointer;
  color: var(--gray1);
  font-size: 27px;
}
