.add-items-modal {
  fsp-ui-table tr td:first-of-type {
    padding-left: 10px;
  }
}
.top-icon-minus-2 {
  fa-icon {
    position: relative;
    top: -3px;
  }
}
