.fsp-location-modal {
  fsp-ui-label {
    margin-top: 10px;
    display: block;
  }

  .no-margin-top {
    margin-top: 0;
  }

  .logo-upload-container {
    height: 150px;
    width: 300px;
    background: var(--white);
    display: grid;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--gray2);
  }

  .attachment-image {
    max-width: 300px;
    border: 1px solid var(--gray2);
    margin-bottom: 8px;
  }
}
