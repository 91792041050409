// Variable overrides for our build
$roboto-font-path: '/Content/fonts';
$fa-font-path: '~/../assets/fonts';

$icon-font: 'Font Awesome 6 Pro';
$icon-font-weight: 900;

$grid-cell-font-weight: 400;
$event-font-weight: 400;
$event-selected-font-weight: 400;

// Extend the bryntum themes
@import '@bryntum/core-thin/sass/themes/material';
@import '@bryntum/grid-thin/sass/themes/material';
@import '@bryntum/scheduler-thin/sass/themes/material';
@import '@bryntum/calendar-thin/sass/themes/material';

// Override/extend theme styles for each bryntum component
@import '_scheduler_overrides.scss';
@import '_calendar_overrides.scss';

// Override the theme-info rule with new theme info
body {
    --bryntum-theme-info : {"name":"ProSolidMaterial"};
}

@font-face {
  font-family: 'Font Awesome 6 Pro';
  src: url('/App/assets/fonts/fa-solid-900.woff2') format('woff2'),
       url('/App/assets/fonts/fa-solid-900.ttf') format('tff');
  font-weight: 900;
}

@font-face {
  font-family: 'Font Awesome 6 Pro';
  src: url('/App/assets/fonts/fa-regular-400.woff2') format('woff2'),
       url('/App/assets/fonts/fa-regular-400.ttf') format('tff');
  font-weight: 400;
}
