.b-scheduler {
  font-size: 13px;
  font-weight: 400;
}

.b-grid-cell {
  font-weight: 400;
}

//NOTE: targets Scheduling Group resources in Day Horizontal view
.b-grid-subgrid-locked.b-first-visible-child {
  .scheduling-group-resource {
    .b-grid-cell {
      color: var(--blue);
      font-weight: bold;
    }
  }
}

.mat-flat-button.book-now-button {
  .mat-button-wrapper {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
  }
}

.b-sch-event {
  border-radius: 2px;
}

.b-sch-event-content {
  font-size: 13px !important;
}

// Vertical resource column style overrides
.b-resourceheader-cell {
  flex-direction: column;
  text-align: center;
  white-space: normal;
  overflow-y: visible;
  //NOTE: targets Scheduling Group resources in Day Vertical view
  &.scheduling-group-resource {
    color: var(--blue);
    font-weight: bold;
  }
}

// Schedule closing styles
.b-grid-header .b-sch-timerange.scheduled-closing {
  background: none !important;
}

.b-timeline-subgrid .b-sch-timerange.scheduled-closing {
  color: var(--black);
}

.scheduled-closing {
  background: whitesmoke url('/images/scheduled-closing.png') repeat !important;
  padding-left: 5px;
  padding-top: 3px;
  font-weight: bold;
  align-items: start;
  text-align: left;
  justify-content: left;
}

// Horizontal time indicator
.b-grid-header .b-sch-line.current-time-indicator {
  color: var(--blue);
  font-size: 16px;
  background-color: transparent !important;
  padding: 0;

  margin-left: -4px;
  margin-bottom: -6px;
}

.b-timeline-subgrid .b-sch-line.current-time-indicator {
  border-left: 2px dotted var(--blue);
}

.b-verticaltimeaxis-row .scheduled-closing {
  display: none;
}

// Horizontal civil twilight indicator
.b-grid-header .b-sch-line.civil-twilight-indicator {
  color: var(--gray2);
  font-size: 14px;
  background-color: transparent !important;
  padding: 0;
  margin-left: -4px;
  margin-bottom: -6px;

  &.sunrise {
    margin-left: -7px;
    margin-bottom: -6px;
  }
  &.sunset {
    margin-bottom: -6px;
  }
}

.b-grid-header .b-sch-range.civil-twilight-indicator {
  background-color: transparent;
}

.b-timeline-subgrid .b-sch-line.civil-twilight-indicator {
  border-left: 2px dotted var(--gray2);
}

// Vertical time indicator
.b-verticaltimeaxis-cell .current-time-indicator {
  color: var(--blue);
  font-size: 16px;
  background-color: transparent !important;
  padding: 0;
  width: 20px;
  margin-top: -7px;
}
.b-verticaltimeaxiscolumn .b-sch-timerange:before {
  transform: rotate(-90deg) !important;
}

.b-sch-vertical .b-timeline-subgrid .b-sch-line.current-time-indicator {
  border-top: 2px dotted var(--blue);
}

// Vertical civil twilight indicator
.b-verticaltimeaxis-cell .civil-twilight-indicator {
  color: var(--gray2);
  font-size: 16px;
  background-color: transparent !important;
  padding: 0;
  width: 20px;
  margin-top: -7px;
}

.b-sch-vertical .b-timeline-subgrid .b-sch-line.civil-twilight-indicator {
  border-top: 2px dotted var(--gray2);
}

// Unavailable resources
.b-sch-resourcetimerange {
  color: var(--gray2);
  .b-sch-event-content {
    font-size: 400 !important;
  }
}

.b-column-line {
  border-color: var(--gray3);
}

.b-stripe {
  .b-grid-row.b-even,
  .b-grid-row.b-odd {
    border-bottom: 1px solid var(--gray3);
  }
}

.scheduler-container .b-horizontaltimeaxis .b-sch-header-timeaxis-cell {
  border-inline-start: 1px solid var(--gray3);
  .b-sch-header-text {
    color: var(--gray8);
    font-size: 14px;
    font-weight: 700;
  }
}

.scheduler-container
  .b-verticaltimeaxis
  .b-sch-header-timeaxis-cell
  .b-sch-header-text {
  color: var(--gray8);
}

// Auth/ack reservation indicator
.error-border-left {
  border-left: var(--red) 4px solid !important;
}

.instUnavailable {
  z-index: 1;
  background: #ccc;
  display: flex;
  height: 30px;
  align-items: center;
}

.b-grid-subgrid-locked {
  .mxstatus-ok.horizontal {
    border-left: 4px solid #e1effc;
  }
  .mxstatus-expired.horizontal {
    border-left: 4px solid #f20100;
  }
  .mxstatus-warning.horizontal {
    border-left: 4px solid #ffcf40;
  }
}

//Dim/highlight smart scheduling resources while dragging from event list
.b-grid-row.horizontal.dim-resource-on-drag {
  .b-timeaxis-cell {
    background: var(--gray3);
    opacity: 0.8;
    &:hover {
      border-top: 1px dashed var(--gray1);
      border-bottom: 1px dashed var(--gray1);
    }
  }
  .b-grid-cell {
    background: var(--gray4) !important;
    color: var(--gray2);
  }
}

.b-grid-row.horizontal.highlight-resource-on-drag {
  .b-timeaxis-cell {
    background: var(--white);
    &:hover {
      border-top: 1px dashed var(--gray1);
      border-bottom: 1px dashed var(--gray1);
    }
  }
  .b-grid-cell {
    background: var(--white) !important;
  }
}

.mxstatus-expired.vertical {
  border-bottom: 4px solid #f20100;
}
.mxstatus-warning.vertical {
  border-bottom: 4px solid #ffcf40;
}
.mxstatus-ok.vertical {
  border-bottom: 4px solid #e1effc;
}

// Resource status classes
.reserved {
  background-color: #ffefbd;
}

.reserved-resource {
  background-color: rgba(255, 239, 189, 0.5) !important;
}

.retired {
  background-color: #ffd9d8;
}

.retired-resource {
  background-color: rgba(255, 217, 216, 0.5) !important;
}

.b-cal-event-bar-container {
  .b-cal-event-wrap {
    &:not(.b-solid-bar) {
      &.b-cal-event-reveal,
      &.b-editing,
      &.b-selected,
      &.b-active {
        .b-cal-event {
          background: unset;
          &:hover {
            background: unset;
          }
        }
      }
    }
  }
}

.b-daycellrenderer .b-cal-event-bar-container .b-cal-event-wrap {
  z-index: unset;
}

.scheduler-container {
  .b-sch-vertical {
    .b-sch-event .b-sch-event-content {
      margin: 0.5em 0;
    }
    .b-sch-event-wrap.b-sch-vertical {
      border-bottom: 2px solid transparent;
    }
    .b-sch-foreground-canvas {
      background: repeating-linear-gradient(
        to bottom,
        #ffffffcc,
        #ffffffcc calc(var(--height)),
        #f4f4f4cc calc(var(--height)),
        #f4f4f4cc calc(calc(var(--height) * 2))
      );
    }
    .b-column-lines-canvas {
      .b-column-line {
        border-color: var(--gray1);
      }
    }
    .b-grid-footer-scroller,
    .b-grid-header-scroller,
    .b-sticky-headers .b-grid-subgrid-locked {
      width: 50px !important;
    }
  }
}

.b-sch-event-wrap.b-sch-style-plain.b-sch-color-green {
  .b-sch-event {
    background-color: var(--blue);
    &.drag-create-event {
      opacity: 0.5;
    }
  }
}

.b-button.b-tool,
.b-tool {
  color: var(--white);
}

// Additional styles for draft reservations configured in ScheduleController.cs
.draft-reservation {
  border: 1px dashed var(--black);
  .b-sch-event-content .draft-icon::before {
    font-family: $icon-font;
    font-size: 9px;
    content: '\f304';
    margin-right: 8px;
    font-weight: 900;
    position: relative;
    top: -1px;
  }

  &.resource-overlap {
    border: 2px dashed var(--red);
  }
}
