:root {
  --dropDownBoxShadow: -2px 2px 4px 1px rgba(0, 0, 0, 0.1),
    0 2px 4px 0px rgba(0, 0, 0, 0.1), 2px 2px 4px 1px rgba(0, 0, 0, 0.1);
}

.blue-text {
  color: var(--blue) !important;
}

.bg-gray3 {
  background-color: var(--gray3);
}

.bg-gray4 {
  background-color: var(--gray4);
}

.bg-gray5 {
  background-color: var(--gray5);
}

.bg-light-blue {
  background-color: var(--lightBlue);
}

.bg-white {
  background-color: var(--white);
}

.custom-backdrop {
  right: 80px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrap-anywhere {
  overflow-wrap: anywhere;
}

.wrapped-option-text {
  white-space: normal;
  line-height: 20px;
  padding: 5px 0;
}

.height-auto {
  height: auto !important;
}

.arrow-down-icon .svg-inline--fa {
  vertical-align: initial;
}

.d-flex {
  display: flex;

  &-column {
    display: flex;
    flex-direction: column;
  }

  &.align-center {
    align-items: center;
  }

  &.align-end {
    align-items: flex-end;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.jc-start {
    justify-content: start;
  }

  &.jc-end {
    justify-content: end;
  }
}

.flex-1 {
  flex: 1;
}

.d-grid {
  display: grid;
}

.disabled:not(.fsp-label) {
  opacity: 0.38;
}

.divider {
  height: 1px;
  background-color: var(--borderGray);
}

.flex-wrap {
  flex-wrap: wrap;
}

.fsp-hidden {
  // the hidden class is in all.less and sets display to none :-(
  visibility: hidden;
}

.fsp-display-none {
  display: none !important;
}

.full-span {
  grid-column: 1/-1;
}

.full-width {
  width: 100%;
}

.pointer {
  cursor: pointer !important;
}

.not-allowed {
  cursor: not-allowed;

  .mat-button-toggle-button {
    cursor: not-allowed !important;
  }
}

.block {
  display: block !important;
}

.no-overflow-x {
  overflow-x: hidden !important;
}

.ios-extra-margin {
  margin-bottom: 135px !important;
}

.spin4s {
  .fa-spin {
    animation: fa-spin 4s infinite linear !important;
  }
}

.text-capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

$spacing-units: 0, 5, 10, 15, 20, 25, 30, 35, 40;

// Generate margin and padding util classes for units defined above
@each $unit in $spacing-units {
  $properties: (
    'margin': 'm',
    'padding': 'p',
  );
  @each $property, $prefix in $properties {
    .#{$prefix}-#{$unit} {
      #{$property}: #{$unit}px;
    }
    .#{$prefix}t-#{$unit} {
      #{$property}-top: #{$unit}px;
    }
    .#{$prefix}r-#{$unit} {
      #{$property}-right: #{$unit}px;
    }
    .#{$prefix}b-#{$unit} {
      #{$property}-bottom: #{$unit}px;
    }
    .#{$prefix}l-#{$unit} {
      #{$property}-left: #{$unit}px;
    }
  }
}

// Generate gap util classes
@each $unit in $spacing-units {
  .gap-#{$unit} {
    gap: #{$unit}px;
  }
}

$font-sizes: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
  20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
  39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
  58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76,
  77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95,
  96, 97, 98, 99, 100;

// Generate font-size classes
@each $font-size in $font-sizes {
  .fs-#{$font-size} {
    font-size: #{$font-size}px;
  }
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0;
}

.text-wrap {
  text-wrap: wrap;
}

.input-holder-disabled {
  background-color: var(--white);
  width: 100%;
  border: 1px solid var(--gray2);
  height: auto;
  min-height: 31px;
  border-radius: 2px;
  caret-color: var(--gray1);
  padding: 4px 8px;
  margin: 0;
  box-sizing: inherit;
  cursor: no-drop;
}

.center-text {
  text-align: center;
}

.left-align-text {
  text-align: left;
}

.right-align-text {
  text-align: right;
}

.input-holder-disabled {
  background-color: var(--white);
  width: 100%;
  border: 1px solid var(--gray2);
  height: 31px;
  height: auto;
  min-height: 31px;
  border-radius: 2px;
  caret-color: var(--gray1);
  padding: 4px 8px;
  margin: 0;
  box-sizing: inherit;
  cursor: no-drop;
}

.rotate {
  display: inline-block;
  animation: spin 4s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

// Hide zendesk launcher icon
iframe#launcher {
  display: none;
}

@media (max-width: 599px) {
  .full-width-mobile {
    width: 100%;
  }
}
