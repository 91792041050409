.fsp-date-picker {
  &.fsp-form-field.mat-form-field-appearance-outline.ng-invalid.ng-touched {
    .mat-mdc-form-field-icon-suffix {
      border-color: var(--red) !important;
    }
  }

  &.fsp-date-range-picker.fsp-form-field.mat-form-field-appearance-outline
    .mat-mdc-form-field-infix {
    width: 225px;
  }

  .mat-mdc-form-field-label-wrapper {
    padding-top: 0;
    top: 0;
  }

  .fsp-input.mat-mdc-input-element {
    margin-bottom: 0;
    border-radius: 2px 0 0 2px;
  }

  &.fsp-form-field.mat-form-field-appearance-outline {
    .mat-mdc-form-field-infix {
      display: grid;
      line-height: 31px;
      height: 31px;
      position: relative;
      width: 200px;
    }
    .mat-mdc-form-field-icon-suffix {
      position: absolute;
      top: 0;
      right: 0;
      height: 31px;
      line-height: 31px;
      background-color: var(--white);
      border-top: 1px solid var(--gray2);
      border-right: 1px solid var(--gray2);
      border-bottom: 1px solid var(--gray2);
      border-radius: 0 2px 2px 0;

      .mat-mdc-icon-button {
        color: var(--blue);
        line-height: 31px;
        height: 31px !important;
        width: 31px !important;
        padding: 0;

        svg {
          width: 16px !important;
          height: 16px !important;
        }

        .mat-mdc-button-touch-target {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
