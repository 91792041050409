@media (max-width: 600px) {
  mat-dialog-container.mat-mdc-dialog-container
    .fsp-modal
    .course-actions
    .fsp-button,
  mat-dialog-container.mat-mdc-dialog-container
    .fsp-modal
    .timeline-actions
    .fsp-button {
    width: auto;
  }
}
