.fsp-ui-toggle.mat-mdc-slide-toggle {
  height: 31px;
  line-height: 31px;

  &.default-height {
    height: auto;
    line-height: normal;
  }

  label {
    margin: 0;
    padding: 0;
  }

  .mdc-switch {
    margin-right: 8px;
  }
  .mdc-switch:enabled .mdc-switch__track::after {
    background: var(--blue) !important;
  }
  .mdc-switch:enabled .mdc-switch__track::before {
    background: var(--gray1) !important;
  }
  .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after,
  .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
    background: var(--white) !important;
  }

  .mdc-switch:enabled:hover .mdc-switch__track {
    box-shadow: 0 0 0 3px var(--lightBlue2);
  }

  .mdc-switch--selected .mdc-switch__handle-track {
    transform: translateX(12px);
  }
  &.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle-track {
    left: 2px;
  }
  &.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle {
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }

  &.mat-mdc-slide-toggle .mdc-switch {
    width: 28px;
  }

  &.mat-mdc-slide-toggle .mdc-switch .mdc-switch__track {
    border-radius: 8px;
    height: 16px;
  }
}

.mdc-switch__icons {
  display: none;
}

.mat-mdc-slide-toggle .mat-mdc-slide-toggle-ripple,
.mat-mdc-slide-toggle .mdc-switch__ripple::after {
  display: none;
}

// Create spacing between multiple toggles
.fsp-slide-toggle-list {
  .fsp-form-field:not(:first-child) {
    margin-top: 15px;
  }

  // Display hint below toggle labe
  .mat-hint {
    display: block;
  }
}

.slide-toggle-container {
  &.label-position-left {
    display: grid;
    grid-auto-flow: column;
    align-items: center;

    fsp-ui-label {
      margin-top: 0;
      height: 24px;
    }
  }

  &.label-position-top {
    display: grid;
    align-items: center;

    fsp-ui-label {
      margin-top: 0;
      height: 24px;
    }
  }
}

.course-field-list {
  .fsp-ui-toggle.mat-slide-toggle .mat-slide-toggle-content {
    font-weight: normal;
  }
}
