// Overriding styles for Material form field

.fsp-form-full-width-fields {
  .fsp-form-field {
    width: 100%;
  }
}

.horizontal-form-field-container {
  display: grid;
  grid-template-columns: 175px 1fr;
  justify-content: start;
  justify-items: start;
  align-items: center;
  gap: 30px;

  fsp-ui-label {
    justify-self: end;
    line-height: 31px;
    height: 31px;

    &.top-align {
      align-self: flex-start;
      line-height: initial;
      height: initial;
    }

    &.stick-to-top {
      align-self: flex-start;
    }
  }
}

.horizontal-select-container {
  .fsp-select {
    display: grid !important;
    grid-template-columns: 175px 1fr;
    align-items: center;
    justify-items: end;
    gap: 30px;
  }
}

.read-only-form {
  display: grid;
  gap: 15px;

  .horizontal-read-only-form-field {
    display: grid;
    grid-template-columns: 175px 1fr;
    justify-content: start;
    align-items: center;
    gap: 30px;

    &.top-align {
      align-items: start;
    }

    .value {
      font-weight: bold;
    }

    .fsp-label {
      text-align: right;
      margin: 0;
    }
  }
}

.fsp-form-field {
  &.mat-form-field-appearance-outline {
    position: relative;
    display: inline-block;

    &.ng-valid {
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }

    &.full-width {
      width: 100%;
    }

    &.no-hardcoded-width {
      .mat-mdc-form-field-infix {
        width: initial;
      }
    }

    .mat-mdc-form-field-text-prefix,
    .mat-mdc-form-field-text-suffix,
    .mat-mdc-form-field-icon-prefix,
    .mat-mdc-form-field-icon-suffix {
      line-height: 16px;
      position: absolute;
      z-index: 1;
      top: 8px;
    }

    .mat-mdc-form-field-text-prefix,
    .mat-mdc-form-field-icon-prefix {
      color: var(--gray2);
      left: 10px;
    }

    .mat-mdc-form-field-text-suffix,
    .mat-mdc-form-field-icon-suffix {
      color: var(--gray1);
      right: 10px;

      .suffix-icon {
        cursor: pointer;
      }
    }

    .fsp-inline-hint {
      position: absolute;
      color: var(--gray1);
      top: 8px;
      font-size: 12px;
      line-height: 14px;
      right: 10px;
      margin: 0;
    }

    .fsp-inline-bottom-hint {
      position: inherit;
      color: var(--gray1);
      font-size: 12px;
      line-height: 14px;

      &.right {
        float: right;
      }
    }

    .mat-mdc-form-field-infix {
      padding: 0;
      border: none;
      min-height: auto;
    }

    .mat-mdc-form-field-flex {
      padding: 0;
      margin: 0;
    }

    .mat-mdc-select-arrow-wrapper {
      transform: none;
    }

    .mat-mdc-text-field-wrapper {
      margin: 0;
      padding: 0;
    }

    .mdc-notched-outline {
      height: 100%;
      top: 0;
    }

    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border: none;
    }

    .mat-mdc-form-field-subscript-wrapper {
      position: absolute;
      padding: 0;
      margin-top: 20px;
      top: calc(100% - 1.7916666667em);
    }

    &.tight {
      .mat-mdc-form-field-subscript-wrapper {
        margin-top: 0;
      }
    }
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 0;
  }

  .mat-mdc-form-field-error,
  .mat-mdc-form-field-hint {
    font-family: var(--mainFont);
    letter-spacing: normal;
    font-size: 12px;
    line-height: 14px;
  }

  .mat-mdc-form-field-error {
    color: var(--red);
  }
}

// mobile styling
@media (max-width: 599px) {
  .fsp-form-field {
    width: 100%;

    &.prevent-full-width-mobile {
      width: inherit;
    }
  }

  .horizontal-form-field-container {
    gap: 0;
    grid-template-columns: 1fr;
    justify-content: center;

    fsp-ui-label {
      display: block;
      justify-self: start;
      height: inherit;
      line-height: inherit;
    }
  }

  .horizontal-select-container .fsp-select {
    gap: 0;
    grid-template-columns: 1fr;
    justify-content: center;
    justify-items: start;
  }
}
