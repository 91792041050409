pre {
  padding: 0px;
}

.tox.tox-tinymce {
  border-radius: 2px;
}

editor.invalid .tox.tox-tinymce {
  border-color: var(--red);
}

// NOTE: rich-text-html-view styles may need to be repeated in print-preview-css.ts
.rich-text-html-view {
  margin: 1rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: initial;
  line-height: 18px;

  p {
    margin: 0;
  }

  table[border]:not([border='0']):not([style*='border-color']) td,
  table[border]:not([border='0']):not([style*='border-color']) th {
    border: 1px solid var(--gray2);
  }

  table:not([cellpadding]) td,
  table:not([cellpadding]) th {
    padding: 0.4rem;
  }

  .mce-pagebreak {
    border: 1px dashed var(--gray2);
    cursor: default;
    display: block;
    height: 5px;
    margin-top: 15px;
    page-break-before: always;
    width: 100%;
  }
}
