// Modal: https://projects.invisionapp.com/d/main/#/console/20840765/438563785/preview

.fsp-modal {
  padding: 20px;

  .mat-mdc-dialog-title {
    font-family: var(--mainFont) !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    letter-spacing: normal !important;
    line-height: 24px !important;
    margin: 0 !important;
    padding: 0 !important;
    text-transform: capitalize !important;
  }

  .modal-subtitle {
    text-align: center;
    border-top: 1px solid var(--gray3);
    padding-top: 20px;
    margin-bottom: 20px;
  }

  mat-dialog-content {
    background-color: var(--gray5);
    color: var(--black);
    margin: 0;
    padding: 20px;
  }

  &.no-pad {
    mat-dialog-content {
      padding: 0;
    }
  }

  &.white-bg {
    mat-dialog-content {
      background: var(--white);
    }
  }

  .modal-warning-banner {
    background-color: var(--orange);
    color: var(--white);
    padding: 10px 15px;
    margin-bottom: 15px;
    font-size: 16px;

    fa-icon {
      margin-right: 5px;
    }
  }

  .mat-mdc-dialog-actions {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 20px 0 0 0;
    padding: 0;
    min-height: unset;

    .mat-button-base + .mat-button-base {
      margin-left: 0;
    }
  }

  &.no-pad {
    mat-dialog-content {
      padding: 0;
    }
  }

  &.transparent {
    mat-dialog-content {
      background: transparent;
    }
  }
}

.fsp-modal-header {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .spacer,
  .fsp-icon-button {
    height: 28px;
    width: 28px;
    line-height: 28px;
  }

  .fsp-icon-button {
    display: block;
    font-size: 16px;
    text-align: center;
    margin-right: -6px;

    // renamed to prevent conflict with older styles
    &.back-button {
      margin-left: -6px;
    }

    &.close-modal {
      margin-right: -6px;
    }
  }
}

.fsp-modal-entity-summary {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  margin-bottom: 20px;

  > div {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    gap: 30px;
  }

  .secondary-text {
    line-height: normal;
    margin-bottom: 3px;
  }

  .detail-value {
    font-size: 18px;
    font-weight: 500;

    &.right {
      text-align: right;
    }
  }
}

.fsp-policy-update-modal {
  .mat-checkbox-layout {
    white-space: normal;
  }
}

@media (max-width: 1100px) {
  .fsp-modal-entity-summary {
    grid-auto-flow: row;
    gap: 10px;
    justify-content: unset;

    > div {
      grid-auto-flow: row;
      gap: 10px;
    }
  }
}

// Set modal to top of page instead of center
.cdk-global-overlay-wrapper {
  align-items: flex-start !important;
}

// Need both element and class to override
mat-dialog-container.mat-mdc-dialog-container {
  margin-top: 40px;
  min-width: 35vw;
  max-height: 90vh;
  border-radius: 0;

  &:has(.no-sticky-header-footer) {
    max-height: calc(100vh - 80px);
  }

  .mat-mdc-form-field {
    width: 100%;
  }

  .mat-mdc-dialog-actions {
    display: grid;
    grid-auto-flow: column;
    min-height: auto;
  }
}

.no-min-width-modal mat-dialog-container.mat-mdc-dialog-container {
  min-width: unset;
}

// mobile styling
@media (max-width: 599px) {
  mat-dialog-container.mat-mdc-dialog-container,
  mat-dialog-container.mat-mdc-dialog-container:has(.no-sticky-header-footer) {
    margin-top: 0;
    width: 100vw;
    height: 100vh;
    max-height: unset;

    .fsp-modal {
      .mat-mdc-dialog-title {
        font-size: 18px !important;
      }

      .mat-mdc-dialog-content.ios-triple-button-modal {
        max-height: calc(65vh - 45px);
      }

      .mat-mdc-dialog-actions {
        grid-auto-flow: row;
        grid-template-columns: 1fr;
      }

      fsp-ui-button,
      .fsp-button {
        width: 100%;
      }
    }
  }

  .cdk-global-overlay-wrapper {
    margin: 0;
  }

  .cdk-overlay-pane {
    max-width: 100vw !important;
  }
}

.short-label {
  width: 150px;
}

.margin-left-20 {
  margin-left: 20px;
}

.gray-background {
  background: var(--gray4) !important;
}

.gray-disabled {
  background: var(--gray3) !important;
}
