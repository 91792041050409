.fsp-loading-spinner-container {
  background-color: var(--gray4);
  padding: 40px;
  display: flex;
  justify-content: center;

  &.align-left {
    justify-content: flex-start;
  }

  &.fill-container {
    align-items: center;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &.semi-transparent-background {
    background-color: rgba(255, 255, 255, 0.5);
  }

  &.transparent-background {
    background-color: transparent;
  }

  &.margin-top {
    margin-top: 10px;
  }

  &.small {
    padding: 0;
  }

  .mat-mdc-progress-spinner circle,
  .mat-mdc-spinner circle {
    stroke: var(--gray2);
  }

  &.black {
    .mat-mdc-progress-spinner circle,
    .mat-mdc-spinner circle {
      stroke: var(--black);
    }
  }
}
