// List: https://projects.invisionapp.com/d/main#/console/20840765/439250831/preview

.fsp-ui-list {
  // margin-bottom: 20px;

  &.mat-mdc-list-base {
    padding-bottom: 0;
  }

  &.list-with-header {
    .list-header {
      border-bottom: 1px solid var(--gray3);
      background-color: var(--lightBlue);
      padding: 8px 20px;
      font-weight: bold;
      line-height: initial;

      span {
        line-height: initial;
      }
    }

    .mobile-label {
      font-weight: normal;
      width: 110px; // default width. may need to be overridden depending on length of labels
      text-transform: uppercase;
      color: var(--gray1);
      font-size: 12px;
      line-height: 16px;
    }

    .fsp-ui-list-item.mat-mdc-list-item:not(.include-top-border):first-of-type {
      border-top: none;
    }
  }

  &.button-list {
    .item-text {
      font-weight: bold;
      text-wrap: wrap;
    }
  }

  &.checkbox-list {
    &.mat-mdc-list-base {
      padding-top: 0;
    }

    .list-item-wrapper {
      align-items: flex-start;
      justify-content: flex-start;
      gap: 10px;
    }

    .mat-checkbox-inner-container {
      margin: 0 10px auto 0;
    }
  }

  &.action-list {
    .fsp-ui-list-item.mat-mdc-list-item .mdc-list-item__content {
      padding: 0;

      .list-item-wrapper {
        padding: 15px 10px;
        &.list-item-with-button {
          padding: 0px 0px 0px 10px;
        }

        &.list-item-with-toggle {
          padding: 7px 10px;
        }
      }

      fa-icon {
        margin-left: 20px;
      }
    }
  }

  .list-item-wrapper {
    display: grid;
    grid-auto-flow: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    height: 100%;

    > :first-child {
      display: flex;
    }
  }

  .list-item-button {
    padding: 8px 10px;
  }

  .fsp-ui-list-item {
    &.header {
      border-top: none !important;
      &.pl-10 {
        .mdc-list-item__content {
          padding: 0px 10px !important;
        }
      }
      .mdc-list-item__content {
        padding: 0px 40px !important;
        font-weight: bold;
        background: var(--gray3);
      }
    }

    &.mat-mdc-list-item {
      height: auto;
      box-sizing: border-box;
      border-bottom: 1px solid var(--gray3);
      padding: 0;

      &:first-of-type {
        border-top: 1px solid var(--gray3);
        &.header {
          border-top: none;
          border-bottom: none;
          &.pl-10 {
            .mdc-list-item__content {
              padding: 0px 10px;
            }
          }
          .mdc-list-item__content {
            padding: 0px 40px;
            font-weight: bold;
            background: var(--gray3);
          }
        }
      }

      &:hover,
      &:focus {
        background: var(--gray4);
      }

      &.active {
        background: var(--blue);
        .mdc-list-item__primary-text {
          color: var(--white);
        }
        &.green {
          background: var(--green);
        }
      }

      &.draggable-list-item {
        .mdc-list-item__content {
          padding-left: 0; // want left padding to come from draggable icon so there is it is easier to drag
        }
      }

      &.left-border {
        .mdc-list-item__content {
          border-left: 3px solid var(--green);
        }
      }

      .mdc-list-item__content {
        padding: 10px;
      }
    }

    .left-container {
      display: flex;
      line-height: 31px;
      align-self: flex-start;

      .display-name-container {
        padding-left: 10px;
      }

      .display-name {
        font-weight: bold;
        text-wrap: wrap;
      }

      .default-label {
        font-weight: bold;
        margin-top: 3px;
      }
    }

    fsp-ui-split-button {
      align-self: flex-start;
    }
  }
}

.checkbox-list-group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--lightBlue);
  padding: 10px;
  text-transform: capitalize;
}

.fsp-ui-list,
.cdk-drag-preview {
  .draggable-icon {
    padding: 0 10px;
    cursor: move;
  }
}

.cdk-drag-disabled {
  .draggable-icon {
    cursor: wait;

    &.disabled {
      cursor: default;
      color: var(--gray1);
    }
  }
}

// The item you are dragging
.cdk-drag-preview {
  background-color: var(--white);
  box-sizing: border-box;
  border-radius: 0;
  padding: 10px;
  padding-left: 0;
  cursor: move;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);

  .list-item-wrapper {
    display: grid;
    grid-auto-flow: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    height: 100%;
  }

  .display-name-container {
    padding-left: 10px;
  }

  .item-text {
    font-weight: bold;
  }
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.fsp-ui-list.cdk-drop-list-dragging
  .fsp-ui-list-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

// program modal course list styles
.move-cursor {
  cursor: move;
}

.program-modal-courses-list {
  &.mat-mdc-list-base .mat-mdc-list-item .mdc-list-item__content {
    padding: 0;
  }
  .cdk-drag-preview.course-drag-preview {
    background-color: var(--gray5);
    border-radius: 3px;
    cursor: move;
    box-shadow:
      0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
    height: 65px !important;
    .course-list-item {
      margin-bottom: 5px;
    }
  }
}
