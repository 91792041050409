.fsp-tab-bar {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  border: solid 1px var(--gray3);
  border-top: none;
  background-color: var(--gray4);
  padding: 8px 4px 0 20px;

  .mat-mdc-tab-nav-bar {
    border-bottom: none;
    margin: 0;
  }

  .expansion-chevrons {
    color: var(--gray1);
    padding-top: 8px;
    position: relative;
  }
}
