.aircraft-add-edit-modal {
  .delete-button {
    button.btn.btn-default {
      height: 31px;
      background-color: var(--red);
      color: var(--white);
      margin-top: 0;
      font-size: 14px;
      padding: 0 15px;
      border-radius: 3px;
    }
  }

  .mat-expansion-panel-body {
    padding: 20px !important;
    background-color: var(--gray5) !important;
  }
  @media (max-width: 600px) {
    .delete-button button.btn.btn-default {
      width: 100%;
    }
  }
}
