.infinite-scroll-overlay-container {
  background-color: var(--white);
  box-shadow:
    0 2px 4px -1px #0003,
    0 4px 5px #00000024,
    0 1px 10px #0000001f;
  width: 100%;

  .mat-mdc-option {
    min-height: auto;
    height: 31px;
    line-height: 31px;
    font-size: inherit;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover:not(.mdc-list-item--disabled),
    &:focus:not(.mdc-list-item--disabled) {
      color: var(--black);
      background-color: var(--gray3);
    }

    &.disabled {
      pointer-events: none;
    }

    .ellipsis {
      padding-right: 8px;
    }
  }

  fsp-ui-loading-spinner {
    display: block;
    padding: 10px;
  }

  .helper-text {
    color: var(--gray1);
    padding: 10px;
  }

  .add-button {
    color: var(--blue);
    cursor: pointer;
  }

  .fsp-autocomplete-tabs-container {
    padding: 10px;
    border-bottom: 1px solid var(--gray3);
    ::ng-deep .mat-button-toggle-group {
      margin-right: 0;
    }
  }
}

.mat-mdc-input-element.full-opacity-placeholder::placeholder {
  color: var(--black) !important;
  opacity: 1 !important;
}

.cdk-virtual-scrollable {
  overflow-x: hidden;
}
