.fsp-chip {
  display: inline-block;
  height: 20px;
  color: var(--black);
  background-color: var(--gray3);
  line-height: 20px;
  max-width: 96%;
  padding: 0 10px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;

  &.round {
    border-radius: 10px;
  }

  &.status-chip {
    padding: 0 15px;
    fa-icon {
      margin-right: 5px;
    }
  }

  &.square {
    border-radius: 3px;
  }

  &.blue {
    background-color: var(--blue);
    color: var(--white);
    font-weight: 500;
  }

  &.green {
    background-color: var(--green);
    color: var(--white);
  }

  &.red {
    background-color: var(--red);
    color: var(--white);
  }

  &.gray {
    color: var(--black);
    background-color: var(--gray3);
  }

  &.dark-gray {
    color: var(--white);
    background-color: var(--gray1);
  }

  &.dark-green {
    color: var(--white);
    background-color: var(--darkGreen);
  }

  &.gray-text {
    color: var(--gray1);
    background-color: var(--gray3);
  }

  &.light-orange {
    background-color: var(--lightOrange);
    color: var(--black);
  }

  &.orange {
    color: var(--white);
    background-color: var(--orange);
  }

  &.orange-border {
    border: 1px solid var(--orange);
    line-height: 18px;
  }

  &.flat {
    background-color: var(--white);
    border: 1px solid var(--gray1);
    color: var(--gray1);
    font-size: 14px;
    font-weight: normal;

    &.green {
      border-color: var(--green);
      color: var(--green);
    }

    &.red {
      border-color: var(--red);
      color: var(--red);
    }
  }

  &.bordered {
    color: var(--gray1);
    background-color: var(--white);
    border: 1px solid var(--gray1);
  }

  .remove-chip-icon.mat-chip-remove {
    cursor: pointer;
    margin-left: 5px;
    opacity: 1;
  }

  &.weather-chip {
    height: fit-content;
    white-space: normal;
    text-align: left;
    display: inline-block;
    padding: 6px 15px;
    max-width: none;
    &.vfr {
      background: var(--lightGreen);
      .flight-condition {
        color: var(--green);
      }
      &:hover {
        background: var(--lightGreen2);
      }
    }

    &.mvfr {
      background: var(--lightBlue3);
      .flight-condition {
        color: var(--blue2);
      }
      &:hover {
        background: var(--lightBlue4);
      }
    }

    &.ifr {
      background: var(--lightRed);
      .flight-condition {
        color: var(--red);
      }
      &:hover {
        background: var(--lightRed3);
      }
    }

    &.lifr {
      background: var(--lightPurple);
      .flight-condition {
        color: var(--purple);
      }
      &:hover {
        background: var(--lightPurple2);
      }
    }

    &.weather-chip-alert {
      .fsp-alert-icon {
        font-size: 12px;
        margin-right: 5px;
      }
      a {
        font-weight: 500;
        margin-left: 5px;
        display: inline-block;
        white-space: nowrap;
      }
    }

    &.data-alert {
      background: var(--gray4);
      border: 1px solid var(--gray2);
      .fsp-alert-icon {
        color: var(--gray1);
      }
    }

    &.warning-alert {
      background: var(--lightOrange);
      border: 1px solid var(--orange);
    }

    &.mat-chip.mat-standard-chip::after {
      background: none !important;
    }
  }

  &.small {
    height: 20px;
  }
}

.fsp-chip-with-count {
  display: inline-block;
  height: 20px;
  color: var(--black);
  background-color: var(--gray3);
  line-height: 20px;
  max-width: 96%;
  padding: 0 10px;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  transition: none !important;
  box-shadow: none !important;

  &::after {
    background: none !important;
  }

  &.light-blue {
    color: var(--black);
    background-color: var(--lightBlue) !important;
  }

  .count {
    font-weight: bold;
    margin-left: 10px;
  }
}

mat-chip-grid.fsp-chip-list {
  background-color: var(--white);
  border: 1px solid var(--gray2);
  border-radius: 2px;
  padding: 3px 8px;
  min-height: 31px;

  &:focus-within {
    box-shadow: 0 0 0 2px var(--lightBlue2);
  }

  input {
    cursor: pointer;
  }

  mat-chip-row {
    height: 23px !important;

    &.mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action
      .mdc-evolution-chip__action--primary {
      padding: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action {
      .mat-mdc-chip-action-label {
        overflow: inherit;
      }

      .mdc-evolution-chip__action--trailing {
        padding-right: 0;
      }
    }

    &.mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic
      .mdc-evolution-chip__graphic {
      padding: 0;
    }
  }

  &.error {
    border-color: var(--red);
  }

  &.legacy-styling {
    border: 1px solid #ebebeb;
  }
}

mat-chip-set {
  &.mdc-evolution-chip-set .mdc-evolution-chip {
    margin-top: 0;
    margin-bottom: 0;
  }

  &.inline-view-chip-set {
    padding: 0 3px;
    border-radius: 2px;
    .mdc-evolution-chip-set__chips {
      align-items: center;
    }
    .mat-mdc-chip {
      margin-top: 3px;
      margin-bottom: 3px;
    }
    .inline-edit-icon {
      color: var(--gray2);
      font-size: 10px;
      padding: 0 5px 0 10px;
    }
    .empty-message {
      margin-left: 8px;
      color: var(--gray2);
    }
    &.can-edit:hover {
      background: var(--gray3);
      cursor: pointer;
      .fsp-chip {
        background-image: linear-gradient(
          rgb(0 0 0/20%) 0 0
        ); // Darken background only
      }
      .ellipsis {
        cursor: pointer;
      }
    }
  }

  mat-chip {
    &.mat-mdc-standard-chip .mdc-evolution-chip__action--primary {
      padding: 0;
    }
  }
}

fsp-app-label-autocomplete-chips-input {
  display: block;
  overflow: hidden;
}
