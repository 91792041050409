// Typography: https://projects.invisionapp.com/share/UPZINCO7H6S#/screens/438788361_typography
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root {
  --mainFont: 'Roboto', Arial, Helvetica, sans-serif;
}

body,
.mat-mdc-form-field {
  color: var(--black);
  font-family: var(--mainFont);
  font-size: 14px;
  font-weight: 400;
  // line-height: 16px; // should this be 16 or 18?
  letter-spacing: normal;
}

.mat-mdc-form-field {
  line-height: 16px !important;
}

// TODO: Remove fsp-styleguide from everywhere once entire project uses new styleguide
.fsp-styleguide {
  // breadcrumbs, etc.
  .secondary-text {
    color: var(--gray1);
    font-size: 12px;
    line-height: 14px;
  }

  .empty-text {
    color: var(--gray1);

    &.top-margin {
      margin-top: 15px;
    }
  }

  .gray-text {
    color: var(--gray1);
  }

  .green-text {
    color: var(--green);
  }

  .red-text {
    color: var(--red);
  }

  .orange-text {
    color: var(--orange);
  }

  .blue-text {
    color: var(--blue);
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: var(--black);
    font-weight: 400;
    margin: 0;
  }

  h1 {
    font-size: 24px;
    line-height: 28px;
  }

  h2 {
    font-size: 20px;
    line-height: 24px;
  }

  h3 {
    font-size: 18px;
    line-height: 21px;
  }

  h4 {
    font-size: 16px;
    line-height: 19px;
  }

  h5 {
    font-size: 14px;
    line-height: 18px;
  }

  a {
    color: var(--blue);
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    &.no-underline {
      text-decoration: none;
    }

    &.secondary {
      font-size: 12px;
      line-height: 14px;
    }
  }

  // Remove with angular js toaster adapter uiReservationToaster
  .toaster-text {
    a {
      color: var(--white);
      text-decoration: none;
    }
  }
}

p {
  margin: 0 0 10px;
}

.bold {
  font-weight: bold;
}

.mat-error,
.mat-mdc-form-field-error,
.mat-hint,
.mat-mdc-form-field-hint {
  font-size: 12px;
  line-height: 14px;
}

.mat-mdc-form-field-hint {
  color: var(--gray1);
  &.disabled {
    color: var(--gray2);
  }
}

@media (max-width: 599px) {
  .fsp-styleguide {
    h1 {
      font-size: 20px;
      line-height: 24px;
    }

    h2 {
      font-size: 18px;
      line-height: 21px;
    }

    h3 {
      font-size: 16px;
      line-height: 19px;
    }

    h4 {
      font-size: 14px;
      line-height: 16px;
    }
  }
}
