.fsp-header {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  color: var(--gray1);
  height: 37px;
  line-height: 37px;
  margin: 5px 0 40px 0;

  .nav-icon {
    margin-right: 3px;
  }

  .first-name {
    margin: 0 6px;
  }

  .left-container {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: start;
    align-items: center;

    .main-nav-toggle-button {
      font-size: 20px;
      margin-right: 15px;
      color: var(--black);
      cursor: pointer;
    }

    .operator-name {
      display: inline-block;
      color: var(--black);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 300px;
      margin: 0;
      padding-right: 20px;
    }
  }

  .right-container {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 20px;

    a {
      color: var(--gray1);
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: var(--black);
      }
    }

    fsp-ui-timer-button {
      height: 25px;
    }
  }
}

// mobile styling
@media (max-width: 991px) {
  .fsp-header {
    .operator-name {
      max-width: 300px;
    }
  }
}

@media (max-width: 767px) {
  .fsp-header {
    .operator-name {
      max-width: 165px;
    }
    .help-link,
    .settings-link {
      display: none;
    }
  }
}
