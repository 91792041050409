@media print {
  .fsp-drawer-container {
    overflow: visible !important;

    .mat-drawer-content {
      margin-left: 0px !important;
      overflow: visible !important;
    }

    .fsp-main-drawer-content {
      overflow: visible !important;
    }

    .mat-drawer {
      display: none !important;
    }
  }

  .fsp-header {
    display: none !important;
    height: 0px !important;
  }

  #main {
    margin-top: 10px !important;
  }

  #userpilot-resource-centre-frame {
    display: none !important;
  }
}
