.b-calendar-cell.b-today .b-day-num {
  background-color: var(--blue) !important;
}

.b-cal-event-wrap {
  border-radius: 2px;
}

.b-cal-event {
  font-size: 13px !important;
}

.b-cal-event-bar-container .b-cal-event-wrap.b-solid-bar {
  opacity: 1;
}

.b-cal-event-bar-container {
  .b-cal-event {
    background-color: unset;
    color: unset;
  }
}

.b-dayview-day-container .b-calendar-cell .b-cal-event-body {
  background-color: unset;
}

.b-dayview-day-container
  .b-cal-event-wrap.b-selected:not(.b-dragging-item):hover
  .b-cal-event-body,
.b-dayview-day-container
  .b-cal-event-wrap.b-selected:not(.b-dragging-item)
  .b-cal-event-body,
.b-dayview-day-container
  .b-cal-event-wrap.b-cal-tentative-event
  .b-cal-event-body {
  background-color: unset;
}

.b-dayview-day-container .b-cal-event-wrap:hover .b-cal-event-body {
  background-color: unset;
}

.b-cal-cell-header > .b-week-num {
  display: none;
}

.b-cal-event-wrap.b-intraday:not(.b-solid-bar) .b-cal-event .b-cal-event-body {
  color: unset;
}

.week-container {
  .b-monthview {
    min-height: 239px !important;
  }
  .b-monthview .b-weeks-container {
    border: unset;
  }
  .b-monthview .b-calendar-row.b-calendar-weekdays {
    border: none;
  }
  .b-calendar-row {
    border: 1px solid #ddd;
  }
}

.b-weekview,
.b-dayview {
  border-right: 1px solid #ddd;
  .b-dayview-day-content {
    flex: 1;
  }
  .b-calendarrow .b-cal-cell-header {
    color: var(--black);
    padding-top: 5px;
    padding-bottom: 5px;
    border-top: 1px solid #ddd;
    &:not(:first-child) {
      border-left: 1px solid #ddd;
    }
  }
  .b-calendarrow .b-cal-cell-header .b-day-name-date {
    color: var(--black);
    margin: 0;
    font-size: 20px;
    width: 1.5em;
    height: 1.5em;
  }
  .b-calendarrow .b-cal-cell-header.b-today .b-day-name-date {
    background-color: var(--blue);
    color: var(--white);
  }
  .b-calendarrow .b-cal-cell-header .b-day-name-day {
    text-transform: uppercase;
    margin: 0;
    color: var(--gray1);
  }
  .b-event-time {
    display: none;
  }
  .student-unavailability {
    .b-cal-event {
      background-color: var(--gray3);
      color: var(--gray6);
    }
  }
  .student-time-off {
    .b-cal-event {
      background: repeating-linear-gradient(
        155deg,
        var(--gray3),
        var(--gray3),
        10px,
        var(--white) 10px,
        var(--white) 11px
      );
      border: 1px solid var(--gray2);
    }
  }
  .b-dayview-day-detail.b-calendar-cell {
    padding-inline-start: 5px;
    padding-inline-end: 5px;
  }
  .b-dayview-day-container .b-calendar-cell .b-cal-event {
    border-radius: 4px;
  }
  .b-cal-event-wrap {
    padding: 2px 0 3px !important;
    background-color: transparent !important;
  }
}

.student-availability-mobile .b-dayview {
  .b-calendarrow .b-cal-cell-header:not(.b-selected-date) {
    display: none;
  }
  .b-calendarrow
    .b-cal-cell-header.b-current-date:not(.b-today)
    .b-day-name-date {
    background-color: transparent;
    color: var(--black);
  }
}

.b-calendar-week.hide-week {
  visibility: hidden;
  position: absolute;
}

div.b-resourceheader-cell {
  border-inline-end: 1px solid var(--gray3);
}

.b-cal-event .b-cal-event-icon,
.b-cal-event .b-cal-recurrence-icon {
  display: none;
}

.b-cal-event-wrap.b-intraday:not(.b-solid-bar) .b-cal-event:hover {
  background: transparent;
}

.b-cal-event-bar-container {
  padding: 0 2px;
}

.calendar-closing {
  background: whitesmoke url('/images/scheduled-closing.png') repeat !important;
  .b-today .b-day-num {
    border-radius: 50% !important;
    background-color: var(--blue) !important;
    color: var(--white) !important;
  }
}

.calendar-container .b-monthview .b-monthview-content {
  contain: content;
}
.calendar-container .b-monthview .b-weeks-container .b-calendar-row {
  min-height: 239px;
}
.calendar-container
  .b-monthview
  .b-weeks-container
  .b-calendar-row
  .b-other-month {
  &.b-weekend {
    background-color: var(--white);
  }
  .b-day-num {
    visibility: hidden;
  }
  border-inline-end: 1px solid var(--white);
  opacity: 1;
}
.b-monthview .b-weeks-container .b-calendar-row .first-day-of-month {
  border-inline-start: 1px solid var(--gray7);
}

.week-container .b-monthview .b-other-month .b-day-num {
  color: unset;
}

.week-container .b-monthview .b-weeks-container .b-calendar-row:last-child {
  border-bottom: 1px solid var(--gray7);
}

.b-monthview
  .b-calendar-row.b-calendar-weekdays
  .b-calendar-day-header.b-weekend {
  color: var(--gray6);
}

.no-pointer {
  cursor: default !important;
}
