.reservation-popover {
  position: absolute;
  background-color: var(--white);
  padding: 10px;
  border: 1px solid var(--gray3);
  width: fit-content;
  min-width: 210px;
  z-index: 10;

  .work-orders-container {
    border-top: 1px solid var(--gray3);
    margin-top: 10px;
    padding: 10px 0 5px 0;
  }

  .work-order-number {
    color: var(--black);
  }

  .work-order-metadata {
    color: var(--gray1);
  }

  .status {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    align-items: center;
    gap: 10px;
    margin: 5px 0;
    color: var(--gray1);
  }

  .status-color {
    display: inline-block;
    height: 14px;
    width: 14px;
    border-radius: 4px;
  }

  .awaiting-signature {
    fa-icon {
      color: var(--red);
      height: 14px;
      width: 14px;
      padding-right: 7px;
    }
    margin: 5px 0;
  }

  fsp-app-maintenance-status-widget {
    display: block;
    margin-top: 15px;
  }

  .recurring {
    color: var(--orange);

    fa-icon {
      color: var(--gray1);
      margin-right: 3px;
    }
  }

  .reservation-data {
    max-width: 350px;
    &.split-grid {
      display: grid;
      grid-template-columns: 1fr;
      gap: 10px;
    }
  }

  .chip-container {
    display: flex;
    gap: 10px;
  }

  .fsp-chip {
    margin-top: 10px;
  }
}
