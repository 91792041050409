// Status Indicators: https://projects.invisionapp.com/d/main/#/console/20840765/439368112/preview

// possible statuses from API: inactive, ok, warning, expired, calculating, and misconfigured
.status-icon {
  &:before {
    font-family: 'FontAwesome';
    font-size: 16px;
  }

  &.error,
  &.expired,
  &.misconfigured {
    &:before {
      content: '\f071'; // exclamation-triangle
      color: var(--red);
    }
  }

  &.ok,
  &.success {
    &:before {
      content: '\f058'; // check-circle
      color: var(--green);
    }
  }

  &.inactive {
    &:before {
      content: '\f056'; // minus-circle
      color: var(--gray1);
    }
  }

  &.calculating,
  &.recalculating {
    &:before {
      content: '\f252'; // hourglass-half
      color: var(--gray1);
    }
  }

  &.info {
    &:before {
      content: '\f05a'; // info-circle
      color: var(--blue);
    }
  }

  &.alert,
  &.warning {
    &:before {
      content: '\f06a'; // exclamation-circle
      color: var(--orange);
    }
  }

  &.attempting-retries {
    display: inline-block;
    animation: spin 4s linear infinite;
    @keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
}

fa-icon {
  &.danger {
    color: var(--red);
  }

  &.warning {
    color: var(--orange);
  }
}

.fsp-big-icon {
  margin: 0 auto 20px auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: var(--lightBlue);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  fa-icon {
    color: var(--blue);
  }
  fa-icon.center {
    font-size: 50px;
  }
  fa-icon.corner {
    font-size: 20px;
    position: absolute;
    right: 5px;
    bottom: 5px;
  }
  &.success {
    background: var(--lightGreen);
    fa-icon {
      color: var(--green);
    }
  }
}
