.fsp-active-border {
  height: 3px;

  &.shaded-top {
    background: linear-gradient(to top, var(--gray2), var(--gray5));
  }

  &.shaded-bottom {
    background: linear-gradient(to bottom, var(--gray2), var(--gray5));
  }
}
