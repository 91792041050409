.fsp-progress-bar-container {
  background-color: var(--gray4);
  margin-bottom: 20px;
  padding: 20px;

  .message-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .fsp-progress-bar {
    display: grid;
    grid-auto-flow: column;
    gap: 2px;

    .fsp-progress-bar-element {
      height: 9px;
      background-color: var(--white);

      &.active {
        background-color: var(--blue);
      }

      &.first {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &.last {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}
