.fsp-modal.delete-payment-method-modal {
  .mat-mdc-dialog-title {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 5px;

    .credit-card-logo {
      width: 50px;
    }
  }

  .mat-mdc-dialog-content .credit-card-logo {
    width: 40px;
  }

  .want-to-proceed {
    margin-top: 5px;
  }
}
