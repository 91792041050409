.close-icon {
  color: var(--black);
  cursor: pointer;
}

.fsp-alert {
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  padding: 15px 12px;
  margin-bottom: 20px;

  &.small {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    padding: 10px 7px;
    margin-bottom: 10px;
    .fsp-alert-icon {
      font-size: 12px;
      margin-right: 8px;
    }
  }

  &.data-issue {
    background: var(--gray4);
    border: 1px solid var(--gray2);
  }

  &.danger {
    background-color: var(--lightRed);
    border: 1px solid var(--red);
  }

  &.info {
    background-color: var(--lightBlue);
    border: 1px solid var(--blue);
  }

  &.success {
    background-color: var(--lightGreen);
    border: 1px solid var(--green);
  }

  &.warning {
    background-color: var(--lightOrange);
    border: 1px solid var(--orange);
  }

  &.secondary {
    background-color: var(--gray3);
    border: 1px solid var(--borderGray);
  }

  &.tight {
    margin-bottom: 0;
  }

  &.regular-weight {
    font-weight: 400;
  }

  &.simple {
    font-weight: 400;
    padding: 10px 0 0 0;
    background-color: transparent;
    border: none;
  }

  &.centered {
    justify-content: center;
  }

  &.no-pad {
    padding: 0;
  }

  &.gray {
    color: var(--gray1);
    .fsp-alert-icon {
      color: var(--gray1);
    }
  }

  &.reservation-errors {
    font-weight: 400;
    padding-bottom: 5px;
  }

  &.reservation-flight-alert {
    width: 100%;
    margin-top: 10px;
    font-weight: 400;
    text-align: left;
  }

  &.no-border {
    border: none;
  }

  .close {
    align-self: flex-start;
    line-height: 14px;
    padding-left: 8px;
  }
}

.fsp-alert-icon {
  font-size: 16px;
  margin-right: 10px;

  &.large {
    font-size: 20px !important;
    margin-bottom: unset;
  }

  &.data-issue-icon {
    color: var(--gray1);
  }

  &.success-icon {
    color: var(--green);
  }

  &.info-icon {
    color: var(--blue);
  }

  &.danger-icon {
    color: var(--red);
  }

  &.warning-icon {
    color: var(--orange);
  }
}

.fsp-inventory-alert-popover {
  color: var(--black);
  background-color: var(--white);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  font-size: 14px;
  line-height: 24px;
  padding: 10px 10px 7px 10px;
  border-radius: 3px;

  fa-icon {
    margin-right: 7px;
    color: var(--orange);
    &.danger {
      color: var(--red);
    }
  }
}

.fsp-inventory-alert {
  display: inline-block;
  margin-right: 3px;
}

.fsp-mobile-inventory-alert {
  border-left: 3px solid var(--orange);
  &.danger {
    border-left-color: var(--red);
  }
}

.fsp-inventory-alert-column {
  position: relative;
  width: 60px;
  padding-right: 0;
  .fsp-inventory-alert {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 20px;
    border-left: 3px solid var(--orange);
    display: flex;
    align-items: center;
    &.danger {
      border-color: var(--red);
    }
    fa-icon {
      position: relative;
      top: 1px;
    }
  }
}

@mixin fsp-flight-alert($bg-color, $stripe-color) {
  border: 1px solid $stripe-color;
  background-image: linear-gradient(
    135deg,
    $stripe-color 8.33%,
    $bg-color 8.33%,
    $bg-color 50%,
    $stripe-color 50%,
    $stripe-color 58.33%,
    $bg-color 58.33%,
    $bg-color 100%
  );
  background-size: 6px 6px;
}

.fsp-flight-alert {
  font-weight: 700;
  &.due-soon {
    @include fsp-flight-alert(var(--lightOrange), var(--orange));
    .b-cal-event {
      background-color: transparent !important;
    }
  }
  &.overdue {
    @include fsp-flight-alert(var(--lightRed), var(--red));
    .b-cal-event {
      background-color: transparent !important;
    }
  }
}

fsp-app-dashboard-alert fsp-ui-alert {
  .fsp-alert {
    margin-bottom: 0px;
  }
}

.alert-narrow .fsp-alert {
  padding: 12px;
}
