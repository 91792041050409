// https://projects.invisionapp.com/d/main/#/console/20840765/444652407/preview

.fsp-main-nav-container {
  width: 222px;
  background-color: var(--gray4);

  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    background-color: var(--white);
    border-bottom: 1px solid var(--gray3);

    .logo {
      max-height: 120px;
      max-width: 180px;
    }
  }

  .scrollable-content {
    max-height: calc(100vh - 145px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .main-nav {
    padding: 10px 0 12px 0;

    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 5px;

      li {
        a {
          display: block;
          text-decoration: none;
          font-size: 16px;
          padding: 0 15px 0 35px;
          line-height: 42px;
          color: var(--gray1);
          cursor: pointer;

          &:hover,
          &.active,
          &:hover .nav-icon,
          &.active .nav-icon {
            color: var(--black);
          }

          &.active {
            background-color: var(--white);
          }

          .nav-icon {
            margin-right: 8px;
            color: var(--gray2);
          }
        }
      }
    }
  }

  .main-nav-bottom-links {
    display: none;
  }

  .main-nav-footer {
    display: grid;
    justify-content: center;
    color: var(--gray1);
    margin-top: 25px;

    .fsp-button {
      color: var(--gray1);

      .mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      fa-icon {
        font-size: 11px;
        margin-right: 3px;
      }
    }
  }
}

// mobile styling
@media (max-width: 991px) {
  .fsp-main-nav-container {
    .logo-container {
      display: none;
    }

    .scrollable-content {
      max-height: calc(100vh - 40px);
    }
  }
}

@media (max-width: 767px) {
  .fsp-main-nav-container {
    .main-nav-bottom-links {
      display: flex;
      justify-content: space-evenly;
      margin-bottom: 30px;

      a {
        color: var(--gray1);
        text-decoration: none;
      }
    }
  }
}
