.fsp-label {
  display: block;
  font-size: 14px;
  color: var(--black);
  margin-bottom: 3px;
  white-space: nowrap;

  &.bold {
    font-weight: bold;
  }

  &.left {
    display: inline;
    margin: 0 10px 0 0;
  }

  &.small {
    font-size: 12px;
    line-height: 14px;
    color: var(--gray1);
    &.black-text {
      color: var(--black);
    }
  }

  &.error {
    color: var(--red);
  }

  &.disabled {
    color: var(--gray2);
    .tooltip-icon {
      color: var(--gray2);
    }
  }

  &.gray1 {
    color: var(--gray1);
  }

  &.gray2 {
    color: var(--gray2);
  }

  &.vert-centered {
    margin-bottom: 0;
  }

  &.wrap {
    white-space: pre-line;
  }

  .tooltip-icon {
    margin-left: 5px;
    color: var(--blue);
  }
}

.info-label {
  display: flex;
  flex-direction: row;
  .fsp-label.left {
    margin: 0;
  }
}

.include-in-totals {
  .fsp-label {
    margin-bottom: 0;
  }
}
