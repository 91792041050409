.fsp-expansion-panel {
  .fsp-expansion-panel-header {
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    justify-content: space-between;

    .caret-icon {
      margin-right: 5px;
    }

    &.fs-12 {
      font-size: 12px !important;
    }

    &.fs-16 {
      font-size: 16px !important;
    }
  }

  .fsp-expansion-panel-body {
    overflow: hidden;
  }
}

.fsp-accordion.mat-accordion {
  .mat-content {
    display: block;
    width: 100%;
  }

  .mat-expansion-panel {
    margin: 0;
    border-radius: 0;

    &:first-of-type,
    &:last-of-type {
      border-radius: 0;
    }

    &:not([class*='mat-elevation-z']) {
      box-shadow: none;
    }
  }

  .mat-expansion-panel-header {
    height: auto;
    padding: 0;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
  }

  .mat-expansion-panel-header[aria-disabled='true'] {
    color: inherit;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  .mat-expansion-panel-content {
    font: inherit;
    letter-spacing: inherit;
    color: black;
    border: 1px solid var(--gray2);
    border-top: 0;
  }

  .mat-expansion-indicator {
    position: absolute;
    top: 15px;
    left: 15px;
  }
}

// Workaround for issue where panels start open, then immediately collapse
// https://github.com/angular/components/issues/13870
.accordion-container-with-closed-panels-on-init {
  .mat-expansion-panel-content {
    // using visibility instead of display so container width doesn't change once visible
    visibility: hidden;
    height: 0;
  }
  &.visible {
    .mat-expansion-panel-content {
      visibility: visible;
      height: auto;
    }
  }
}
