mat-tooltip-component > div {
  background-color: var(--white);
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  font-size: 14px;
  line-height: 18px;

  &.weather-chip-tooltip > div {
    max-width: unset;
    overflow: visible;
    padding: 10px;
    font-size: 12px;
    font-weight: 500;
  }

  &.multiline-full-width-tooltip {
    white-space: pre-line;
    max-width: unset !important;
  }

  &.multiline-tooltip > div {
    white-space: pre-line;
  }
}
