// Layout: https://projects.invisionapp.com/share/UPZINCO7H6S#/screens/438698743_page-Layout

.fsp-drawer-container {
  &.mat-drawer-container {
    color: var(--black);
    background-color: var(--white);
    height: 100vh;

    .mat-drawer-inner-container {
      background-color: var(--gray4);
      overflow: hidden;
    }

    .mat-drawer-content {
      padding: 0 30px 30px 30px;
    }

    .mat-drawer {
      overflow-x: hidden;
      border: none;
    }
  }
}
.fsp-drawer-container.mat-drawer-container
  .mat-drawer-content
  .mat-sidenav-content:has(.schedule-page-container) {
  padding: 0;
}
// Can be used for additional side drawers - ex: on the schedule
.fsp-sidenav-drawer {
  &.mat-drawer-container {
    .mat-sidenav {
      width: 430px;
    }

    .mat-drawer-inner-container {
      background: var(--white);
      overflow: auto;
      padding: 24px 16px 16px 16px;
    }

    .mat-drawer-content {
      padding: 0;
      background: var(--white);
      overflow-x: hidden;
    }

    .mat-drawer-backdrop.mat-drawer-shown {
      background: transparent;
    }
  }
}

.fsp-page-header {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
  align-items: center;
  justify-content: start;
  margin: 40px 0 30px;

  &.space-between {
    justify-content: space-between;
  }

  &.page-with-tabs {
    margin: 40px 0 0 0;
  }

  &.page-with-breadcrumbs {
    margin: 0 0 20px 0;
  }

  &.page-with-tight-breadcrumbs {
    margin-top: -2px;
  }

  &:not(.no-ellipsis) {
    h1 {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .header-actions-wrapper {
    display: grid;
    align-items: center;
    gap: 10px;
    grid-auto-flow: column;
  }

  .title-with-icon {
    display: flex;
    align-items: center;
    fa-icon {
      color: var(--blue);
      margin-left: 10px;
    }
  }
}

.fsp-page-header-tooltip {
  color: var(--blue);
  font-size: 14px;
  position: relative;
  top: -6px;
}

.no-items-in-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;

  h4 {
    color: var(--gray1);
  }

  fa-icon {
    font-size: 50px;
    color: var(--gray2);
  }
}

.component-header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin: 15px 0;

  .info-link {
    align-self: center;
    color: var(--blue);
    margin-left: 10px;
  }
}

fsp-ui-reservation-toaster-adapter {
  display: block;
  position: absolute;
  top: 0;
  z-index: 1000;
}

.fsp-flight-alerts-container {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  width: max-content;
  max-width: 55vw;
  margin-left: -111px; // 222px sidenav width divided in half
}

// mobile styling
@media (max-width: 991px) {
  #sidebar {
    margin-top: 40px;
  }
  .fsp-drawer-container.mat-drawer-container {
    overflow: hidden;
    height: calc(100vh - 39px);
  }
  .fsp-page-header {
    margin-top: 0;
  }
  .fsp-flight-alerts-container {
    max-width: unset;
    margin-left: unset;
  }
}

// mobile styling
@media (max-width: 599px) {
  .fsp-page-header {
    align-items: start;
    grid-template-columns: 1fr;

    &.page-with-breadcrumbs {
      margin: 0 0 10px 0;
    }
  }

  .fsp-sidenav-drawer {
    &.mat-drawer-container {
      .mat-sidenav {
        width: 100%;
      }

      .mat-drawer-inner-container {
        padding: 24px 20px 20px 20px;
      }
    }
  }

  .fsp-flight-alerts-container {
    transform: none;
    left: 0;
    right: 0;
  }
}
