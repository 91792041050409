@media print {
  .fc-event,
  .fc-time,
  .fc-title {
    -webkit-print-color-adjust: exact;
  }

  .fc-content,
  .fc-time,
  .fc-title {
    color: inherit !important;
  }
}
