// Tabs: https://projects.invisionapp.com/share/UPZINCO7H6S#/screens/438790939_tabs

.fsp-tab-nav {
  &.mobile {
    display: none;
  }

  &.mat-mdc-tab-header {
    border-bottom: 1px solid var(--gray3);
  }

  // active state/ink bar
  &.mat-mdc-tab-group,
  &.mat-mdc-tab-nav-bar {
    .mdc-tab-indicator .mdc-tab-indicator__content--underline {
      border-top-width: 4px;
    }
  }

  // regular tabs
  &.mat-mdc-tab-group {
    .mat-mdc-tab-header {
      border-bottom: 1px solid var(--gray3);
      padding: 0 15px;
      background-color: var(--gray4);
    }

    .mat-mdc-tab-body {
      background-color: var(--gray5);
      padding: 15px;
    }
  }
  &.align-to-tab-bar {
    &.mat-mdc-tab-group {
      .mat-mdc-tab-body {
        padding: 15px 0;
      }
    }
  }

  // tab navigation
  &.mat-mdc-tab-nav-bar {
    margin: 20px 0 30px;

    &.equal-margins {
      margin: 20px 0;
    }

    .nav-link-container {
      display: flex;
      &.rendering:hover {
        cursor: progress;
      }
    }
  }

  // link/labels
  &.mat-mdc-tab-group .mat-mdc-tab,
  &.mat-mdc-tab-nav-bar a.mat-mdc-tab-link {
    min-width: auto;
    height: 38px;
    opacity: 1;
    padding: 0 16px;
    text-decoration: none;

    .mdc-tab__text-label {
      color: var(--blue);
      font-family: var(--mainFont);
      font-size: 14px;
      font-weight: 400;
    }

    &.active,
    &.mdc-tab--active {
      .mdc-tab__text-label {
        font-weight: 600;
        // font-family: 'Roboto Bold';
      }
    }

    &.mat-mdc-tab-disabled {
      color: var(--gray2);
    }
  }

  &.transparent {
    .mat-mdc-tab-header {
      background-color: transparent;
    }
  }

  &.manual-tab-navigation.mat-mdc-tab-group
    .mat-mdc-tab-label.mat-mdc-tab-disabled {
    color: var(--blue);
    cursor: pointer;
  }
  .badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    vertical-align: baseline;
    white-space: nowrap;
    text-align: center;
    border-radius: 10px;
  }
  .fsp-badge-alert {
    color: var(--white);
    background-color: var(--red);
    margin-left: 5px;
  }

  &.centered {
    .mat-mdc-tab-link-container .mat-mdc-tab-list .mat-mdc-tab-links {
      justify-content: center;
    }
  }
}

fsp-ui-tab-nav.tab-nav-with-filters {
  display: block;
  background-color: var(--gray5);
  padding: 0 15px;
}

.mobile-nav-underline {
  display: none;
}

@media (max-width: 800px) {
  .mobile-nav-underline {
    display: block;
    width: 100%;
    position: relative;
    top: -1.34375em;
    border-top: 1px solid var(--gray3);
    margin: 0;
  }
  .fsp-tab-nav {
    &.desktop {
      display: none;
    }
    &.mobile {
      display: flex;
      justify-content: center;
      background: none;
      max-width: 800px;

      .mat-mdc-form-field-infix {
        padding-bottom: 0;
        border: none;
        min-height: auto;
      }

      .mdc-notched-outline__leading,
      .mdc-notched-outline__trailing {
        border: none;
      }

      .blue-line {
        height: 4px;
        background-color: var(--blue);
      }

      .mat-mdc-select-value-text {
        color: var(--blue);
        font-weight: bold;
      }
      .mat-mdc-select-arrow {
        color: var(--blue);
      }

      .mat-mdc-form-field-underline {
        height: 4px;
        background-color: var(--blue);

        .mat-mdc-form-field-ripple {
          background-color: var(--blue);
        }
      }
    }
  }
}
