@use './add-edit-aircraft-modal';
@use './add-items-modal';
@use './aircraft-groups-list';
@use './aircraft-group-modal';
@use './course-outline';
@use './delete-payment-method-modal';
@use './full-calendar-print';
@use './google-charts';
@use './integration';
@use './inventory';
@use './invoice-modal-printing';
@use './location-modal';
@use './modal-printing';
@use './number-input-no-spin-btn';
@use './one-trust';
@use './prebootstrap';
@use './program-modal';
@use './schedule';
@use './scheduling-dispatch-requirements';
@use './timer-button';
@use './utilities';
@use './training-settings';

body {
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}
