// Breadcrumbs: https://projects.invisionapp.com/d/main/#/console/20840765/438781419/preview

.fsp-styleguide {
  fsp-ui-breadcrumbs {
    display: block;
    margin: -30px 0 20px 0;

    &.secondary-breadcrumbs {
      margin-top: -15px;
    }

    .fsp-breadcrumb {
      display: inline-flex;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        color: var(--gray1);
      }

      fa-icon {
        font-size: 10px;
        margin-right: 7px;
      }
    }
  }
}

// mobile styling
@media (max-width: 991px) {
  .fsp-styleguide {
    fsp-ui-breadcrumbs {
      margin: -30px 0 5px 0;

      .fsp-breadcrumb {
        padding: 15px;
        margin-left: -15px;
      }
    }
  }
}
