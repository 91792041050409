.button-toggle-group {
  &.ta-center {
    text-align: center;
  }
}

.fsp-button-toggle-group {
  border-radius: 2px;
  margin-right: 15px;

  &.no-margin {
    margin: 0;
  }

  &.tighter-padding .mat-button-toggle-button {
    padding: 3px 20px;
  }

  .mat-button-toggle-button {
    padding: 3px 30px;

    span {
      font-size: 13px;
      line-height: 13px;
      padding: 0;
      text-transform: capitalize;
    }
  }

  &.no-text-transform {
    .mat-button-toggle-button {
      span {
        text-transform: none;
      }
    }
  }

  .mat-button-toggle {
    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }

    &:last-of-type {
      border-radius: 0 4px 4px 0;
    }
  }

  .mat-button-toggle {
    border-right: 0 !important;
  }

  &.primary-borders {
    &.mat-button-toggle-group-appearance-standard {
      border: none;
      border-radius: 0;
    }

    .mat-button-toggle {
      border: 1px solid var(--blue);

      + .mat-button-toggle {
        border-left: 1px solid var(--blue);
      }

      &:hover {
        background-color: var(--blue);
        color: var(--white);
      }

      &.mat-button-toggle-disabled {
        background-color: var(--lightBlue2);
        color: var(--white);
      }

      &:last-of-type {
        border-right: 1px solid var(--blue) !important;
      }
    }

    .mat-button-toggle-appearance-standard {
      color: var(--blue);
    }
  }

  &.primary {
    .mat-button-toggle-checked {
      background-color: var(--blue);
      color: var(--white);
    }
  }

  &.red-borders {
    &.mat-button-toggle-group-appearance-standard {
      border: none;
      border-radius: 0;
    }

    .mat-button-toggle {
      border: 1px solid var(--red);

      + .mat-button-toggle {
        border-left: 1px solid var(--red);
      }

      &:hover {
        background-color: var(--red);
        color: var(--white);
      }

      &.mat-button-toggle-disabled {
        background-color: var(--lightRed2);
        color: var(--white);
      }

      &:last-of-type {
        border-right: 1px solid var(--red) !important;
      }
    }

    .mat-button-toggle-appearance-standard {
      color: var(--red);
    }
  }

  &.red {
    .mat-button-toggle-checked {
      background-color: var(--red);
      color: var(--white);
    }
  }

  // To get the desired styles, borders and radius are applied
  // to the buttons themselves
  &.primary-w-primary-borders {
    border: none;
    border-radius: 0;
    background: transparent;

    .mat-button-toggle {
      height: 31px;
      color: var(--blue);
      border-top: 1px solid var(--blue);
      border-bottom: 1px solid var(--blue);

      &:hover {
        border-left: 1px solid var(--blue);
        background-color: var(--grey3);
        color: var(--blue);
      }

      + .mat-button-toggle {
        border-left: 1px solid var(--blue);
      }

      + .mat-button-toggle-checked {
        background-color: var(--blue);
        color: var(--white);
        border-left: 1px solid var(--white);
        &:hover {
          border-left: 1px solid var(--white);
          background-color: var(--darkBlue);
          color: var(--white);
        }
      }

      &.mat-button-toggle-checked {
        background-color: var(--blue);
        color: var(--white);
        &:hover {
          border-left: 1px solid var(--white);
          background-color: var(--darkBlue);
          color: var(--white);
        }
      }

      &.mat-button-toggle-disabled {
        opacity: 0.5;
      }
    }

    .mat-button-toggle:first-child {
      border-left: 1px solid var(--blue);
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      &:hover {
        border-left: 1px solid var(--darkBlue);
      }
    }

    .mat-button-toggle:last-child {
      border-right: 1px solid var(--blue) !important;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }

  // Note: you should test this in mobile to ensure no options get cut off
  &.full-width {
    .mat-button-toggle {
      width: 100%;
    }

    .mat-button-toggle-button {
      padding: 3px 5px;
    }
  }

  &.icon-button-toggle-group {
    border-color: var(--gray2);
    margin-right: 0;

    &.mat-button-toggle-group-appearance-standard {
      border-radius: 2px;
    }

    .mat-button-toggle:first-of-type {
      border-radius: 2px 0 0 2px;
    }

    .mat-button-toggle:last-of-type {
      border-radius: 0 2px 2px 0;
    }

    .mat-button-toggle:not(:first-child) {
      border-left: 1px solid var(--gray2);
    }

    .mat-button-toggle-button {
      padding: 3px 10px;
    }
  }
}

.package-balance-toggle-group {
  .fsp-button-toggle-group {
    margin: 0;
  }
}

@media (max-width: 500px) {
  .fsp-button-toggle-group .mat-button-toggle-button {
    padding: 3px 20px;
  }
}
