.fsp-footer-bar {
  display: grid;
  justify-content: end;
  align-items: center;
  min-height: 43px;
  padding: 0 10px;
  background-color: var(--gray4);
  border-top: solid 1px var(--gray3);
}

.inline-delete-container {
  display: grid;
  gap: 10px;
  grid-auto-flow: column;
  justify-content: end;
  align-items: center;
  padding: 8px 15px;

  .delete-buttons {
    display: grid;
    grid-auto-flow: column;
    gap: 10px;
  }

  &.no-pad {
    padding: 0;
  }

  &.compact {
    font-size: 14px;
    padding: 0;
  }
}

@media (max-width: 599px) {
  .inline-delete-container {
    grid-auto-flow: row;

    .delete-buttons {
      justify-content: start;
    }
  }
}
