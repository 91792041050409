// Card: https://projects.invisionapp.com/d/main/#/console/20840765/439143752/preview

.fsp-card-header,
.fsp-detail-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--gray4);
  color: var(--black);
  padding: 10px 20px;
  height: 45px;
  font-size: 18px;
  border-bottom: 1px solid var(--borderGray);

  mat-slide-toggle {
    font-size: 14px;
  }
}

.fsp-card {
  background-color: var(--gray5);
  padding: 20px;

  mat-mdc-tab-group.no-content {
    .mat-mdc-tab-body-wrapper {
      display: none;
    }
  }
}

.old-dashboard-styles {
  .fsp-card-header,
  .fsp-card {
    background-color: unset;
  }
}

// mobile styling
@media (max-width: 599px) {
  .fsp-card-header,
  .fsp-detail-card-header {
    font-size: 16px;
  }
  fsp-app-dashboard {
    .fsp-card-header:not(.not-stacked) {
      align-items: start;
      display: flex;
      flex-direction: column;
      gap: 5px;
      height: auto;
    }
  }
}
