// SnackBar: https://projects.invisionapp.com/d/main/#/console/20840765/439108536/preview
.fsp-snackbar {
  border-radius: 3px;
  color: var(--black);
  max-width: 100vw;

  &.error {
    background-color: var(--lightRed);
    border: 1px solid var(--red);
  }

  &.info {
    background-color: var(--lightBlue);
    border: 1px solid var(--blue);
  }

  &.success {
    background-color: var(--lightGreen);
    border: 1px solid var(--green);
  }

  &.warn {
    background-color: var(--lightOrange);
    border: 1px solid var(--orange);
  }

  &.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    border-radius: inherit;
    background-color: inherit;
  }
}
