.fsp-attachment-upload {
  display: flex;

  .file-upload-input {
    // opacity is used to hide the file input instead of visibility: hidden or display: none, because
    // assistive technology interprets the latter two styles to mean the file input isn't interactive.
    opacity: 0;
    height: 0;
    width: 0;
  }

  .select-file {
    display: inline-block;
    margin-bottom: 15px;
    width: min-content !important; // needed for iOS issues

    .fsp-button {
      width: min-content !important; // needed for iOS issues
    }
  }

  mat-form-field {
    .mat-mdc-form-field-wrapper {
      margin: 0;
    }

    .mat-mdc-form-field-infix {
      padding: 0.3em 0;
    }

    .mat-mdc-input-element:disabled {
      color: black;
    }
  }
}

.fsp-attachment {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 10px;

  &:first-of-type {
    margin-top: 0;
  }

  &.deleting {
    opacity: 0.5;
    pointer-events: none;
  }

  &.wrap-inline-delete {
    display: grid;
    row-gap: 5px;
    grid-template-areas:
      'name size attachment-actions .'
      'attachment-inline-delete attachment-inline-delete attachment-inline-delete attachment-inline-delete';

    .attachment-inline-delete .fsp-button.red {
      margin: 0 10px 0 0;
    }
  }

  &.vertical-stack {
    display: grid;
    row-gap: 5px;
    grid-template-areas:
      'name name name'
      'size attachment-actions .'
      'attachment-inline-delete attachment-inline-delete attachment-inline-delete';

    .size {
      margin-left: 0;
    }

    .attachment-inline-delete .fsp-button.red {
      margin: 0 10px 0 0;
    }
  }

  .attachment-inline-delete {
    grid-area: attachment-inline-delete;

    .fsp-button.red {
      margin: 0 10px 0 20px;
    }
  }

  .read-only-name {
    color: var(--black);
    &.wrap-name {
      word-break: break-word;
    }
  }

  .name {
    grid-area: name;
    color: var(--black);
    text-decoration: none;

    &.wrap-name {
      word-break: break-word;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .size {
    grid-area: size;
    color: var(--gray1);
    font-size: 12px;
    text-transform: lowercase;
    margin-left: 10px;
  }

  .attachment-actions {
    grid-area: attachment-actions;
    display: grid;
    grid-auto-flow: column;
    gap: 10px;
    margin-left: 10px;
    width: fit-content;
  }

  .download-icon,
  .delete-icon {
    &.disabled {
      pointer-events: none;
    }
  }

  .download-icon {
    color: var(--blue);
  }

  .delete-icon {
    color: var(--red);
  }

  fa-icon:hover {
    cursor: pointer;
  }
}

.attachment-empty-text {
  color: var(--gray1);
}
