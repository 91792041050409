.green-text {
  color: var(--green);
}

.widget-item {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  .secondary-text {
    line-height: 14px;
  }
}

.widget-item-data {
  display: flex;
  flex-wrap: nowrap;
  font-size: 14px;
  line-height: 14px !important;
  color: var(--black);
}

h1.widget-item-data {
  font-size: 16px !important;
}

.widget-item-label {
  max-width: 200px;
  .info-icon {
    font-size: 14px;
    top: 0;
    margin-left: 5px;
  }
}

.widget > * {
  border-bottom: 1px solid #eeeeee;
  font-size: 12px;
  padding: 15px 0;
  &:first-child {
    padding: 0 0 15px;
  }

  &:last-child {
    border-bottom: none;
    padding: 15px 0 0;
  }

  &:first-child:last-child {
    padding: 0;
  }
}

.widget-section-header {
  font-size: 16px;
  margin-bottom: 20px;
}

.widget-section-name {
  color: var(--black);
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 14px;
  a:not(:first-child) {
    margin-left: 10px;
  }
}

@media (max-width: '1050px') {
  .widget-item-label {
    max-width: unset;
  }
}
